import axios from "axios";
import { EnvConfig } from "../EnvConfig";
import { ErrorTexts } from "../../constants/ErrorTexts";

const RoutesFetch = async ({
  method,
  url,
  data,
  params,
  withCredentials = true,
}) => {
  return await axios({
    method,
    url: `${EnvConfig.baseUrl}${url}`,
    data,
    withCredentials,
    params,
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw new Error(ErrorTexts.sww);
    });
};

export { RoutesFetch };
