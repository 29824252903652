import React from "react";

import Menu from "@mui/material/Menu";

function MenuList({
  children,
  showProfileMenu,
  setProfileMenu,
  profileMenu,
  customStyle,
}) {
  return (
    <Menu
      id="basic-menu"
      anchorEl={profileMenu}
      open={showProfileMenu}
      onClose={() => setProfileMenu(null)}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      className={customStyle}
    >
      {children}
    </Menu>
  );
}

export default MenuList;
