import React, { useState } from "react";
import TextComp from "../../../../../components/TextComp";
import CustomModal from "../../../../../components/Modal";
import SelectProductAndReasonModal from "./SelectProductAndReasonModal";

function CancelReturnOrder({ orderData, setUpdateDetails, updateDetails }) {
  const [reasonModal, setReasonModal] = useState(false);
  const [from, setFrom] = useState(null);

  const date = new Date();
  const sevenDaysBeforeTodayDate = new Date(
    date.getTime() - 7 * 24 * 60 * 60 * 1000
  );

  return (
    <div>
      {orderData?.returnedStatus === "done" ? (
        <TextComp
          text={`Order ${
            orderData?.returnedType === "Cancel"
              ? "Cancel"
              : "Return / Exchange"
          } request completed`}
          customStyle={"text-darkGray text-xs tracking-wide underline"}
        />
      ) : orderData?.returnedStatus === "inProcess" ? (
        <TextComp
          text={`Your order ${
            orderData?.returnedType === "Cancel"
              ? "cancel"
              : "return / exchange"
          } request is under process with us.`}
          customStyle={"text-darkGray text-xs tracking-wide underline"}
        />
      ) : orderData?.deliveryStatus === "ordered" ||
        orderData?.deliveryStatus === "packed" ? (
        <div
          onClick={() => {
            setFrom("cancel");
            setReasonModal(true);
          }}
        >
          <TextComp
            text="CANCEL ORDER"
            customStyle={"text-darkGray text-xs tracking-wide underline"}
          />
        </div>
      ) : sevenDaysBeforeTodayDate > orderData?.deliveryDate ? (
        <TextComp
          text="RETURN / EXCHANGE WINDOW CLOSED"
          customStyle={"text-darkGray text-xs tracking-wide underline"}
        />
      ) : orderData?.deliveryStatus === "delivered" ? (
        <div
          onClick={() => {
            setFrom("return");
            setReasonModal(true);
          }}
        >
          <TextComp
            text="RETURN / EXCHANGE ORDER"
            customStyle={"text-darkGray text-xs tracking-wide underline"}
          />
        </div>
      ) : null}

      <CustomModal
        open={reasonModal}
        setOpen={setReasonModal}
        children={
          <SelectProductAndReasonModal
            from={from}
            orderData={orderData}
            open={reasonModal}
            setOpen={setReasonModal}
            setUpdateDetails={setUpdateDetails}
            updateDetails={updateDetails}
          />
        }
      />
    </div>
  );
}

export default CancelReturnOrder;
