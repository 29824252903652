import React, { useEffect, useState } from "react";
import NavbarAndFooterComp from "../../../components/NavbarAndFooterComp";
import SelectInput from "../../../components/TextInput/SelectInput";
import {
  DeliveryStatuses,
  ReturnStatuses,
} from "../../../constants/DeliveryStatuses";
import { RoutesFetch } from "../../../config/RoutesFetch";
import { ErrorTexts } from "../../../constants/ErrorTexts";
import RouteWaiter from "../../../components/RouteWaiter";
import CustomAccordion from "../../../components/CustomAccordion";
import TextComp from "../../../components/TextComp";
import InfiniteScrollComp from "../../../components/InfiniteScrollComp";

import Button from "../../../components/Button";
import DialogBox from "../../../components/DialogBox";
import { errorToastify, successToastify } from "../../../components/Toastify";
import { SuccessTexts } from "../../../constants/SuccessTexts";
import { bagCalculation } from "../../Bag/checkoutCommonFlow/CheckoutFunctions";
import DisplayProducts from "../../ProfileMenu/MyAccount/MyOrders/DisplayProducts";

function ReturnedOrders() {
  const [filter, setFilter] = useState("return");
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState(null);

  return (
    <NavbarAndFooterComp
      children={
        <div className="mx-5 md:mx-10  py-10">
          <SelectInput
            label={"Search by return status"}
            values={ReturnStatuses}
            id="title"
            onChange={(e) => setFilter(e.target.value)}
            defaultValue={"return"}
          />

          <div>
            <InfiniteScrollComp
              url="/getAllOrderReturn"
              responseDataName="orderData"
              params={{ returnStatus: filter }}
              setLoading={setLoading}
              setErrorText={setErrorText}
              data={orderData}
              setData={setOrderData}
              valueOfUseEffectArray={[filter]}
              children={
                <RouteWaiter
                  loading={loading}
                  error={errorText}
                  data={orderData}
                  children={
                    <div>
                      {orderData?.map((item, index) => (
                        <CardsComp
                          item={item}
                          key={index}
                          setLoading={setLoading}
                        />
                      ))}
                    </div>
                  }
                />
              }
            />
          </div>
        </div>
      }
    />
  );
}

const CardsComp = ({ item, setLoading }) => {
  const [totalMRP, setTotalMRP] = useState(null);
  const [totalMRPDiscount, setTotalMRPDiscount] = useState(null);
  const [totalAmountToBePaid, setTotalAmountToBePaid] = useState(null);

  const [returnProcess, setReturnProcess] = useState(item?.returnedStatus);
  const [returnDialogBox, setReturnDialogBox] = useState(false);

  useEffect(() => {
    bagCalculation({
      setTotalMRP,
      setTotalMRPDiscount,
      setTotalAmountToBePaid,
      bagData: item?.checkoutData?.productDetails,
      discountAmount: item?.checkoutData?.discountAmount,
      setData: true,
    });
  }, []);

  const FlexDiv = ({ title, value }) => {
    return (
      <div className="flex flex-row items-center justify-between text-sm tracking-wide font-medium mb-2">
        <TextComp text={title} />
        <TextComp text={value} />
      </div>
    );
  };

  const completeReturnProcess = () => {
    setLoading(true);

    RoutesFetch({
      method: "post",
      url: "/completeReturnProcess",
      data: {
        orderId: item?._id,
      },
    })
      .then((res) => {
        if (res?.msg === "fail") {
          errorToastify(ErrorTexts.sww);
        } else {
          setReturnDialogBox(false);
          setReturnProcess("done");
          successToastify(SuccessTexts.returnProcessCompleted);
        }
      })
      .catch(() => {
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="bg-white shadow my-10 py-5 px-5 cursor-pointer rounded">
      <div className="py-2">
        <CustomAccordion
          title={"User Details"}
          details={
            <div>
              {!item?.userData?.firstName ? null : (
                <FlexDiv
                  title={"Name"}
                  value={`${item?.userData?.firstName} ${item?.userData?.lastName}`}
                />
              )}
              {!item?.userData?.email ? null : (
                <FlexDiv title={"Email"} value={`${item?.userData?.email}`} />
              )}
              <FlexDiv
                title={"Mobile Number"}
                value={`${item?.userData?.mobileNumber}`}
              />
            </div>
          }
        />
      </div>

      <div className="py-2">
        <CustomAccordion
          title={"Product Details"}
          details={
            <div>
              <FlexDiv
                title={"Checkout Id"}
                value={item?.checkoutData?.checkoutId}
              />

              <FlexDiv title={"Order Id"} value={item?._id} />

              {item?.checkoutData?.productDetails?.map((productItem, index) => (
                <DisplayProducts
                  key={index}
                  productItem={productItem}
                  orderData={item}
                />
              ))}
            </div>
          }
        />
      </div>

      <div className="py-2">
        <CustomAccordion
          title={"Return Details"}
          details={
            <div>
              <div className="mb-5">
                <FlexDiv title={"Return Status"} value={returnProcess} />
              </div>

              {item?.returnedProducts?.map((productItem, index) => (
                <DisplayProducts
                  key={index}
                  productItem={productItem}
                  orderData={item}
                />
              ))}

              {returnProcess === "done" ? null : (
                <Button
                  btnText={"Complete Process"}
                  onPress={() => {
                    setReturnDialogBox(true);
                  }}
                />
              )}
            </div>
          }
        />
      </div>

      <div className="py-2">
        <CustomAccordion
          title={"Pricing Details"}
          details={
            <div>
              <FlexDiv title={"Payment Type"} value={item?.paymentMethod} />
              <FlexDiv title={"Payment Status"} value={item?.paymentStatus} />

              {item?.paymentDetails !== null ? (
                <>
                  <FlexDiv
                    title={"Payment Id"}
                    value={item?.paymentDetails?.razorpay_payment_id}
                  />
                  <FlexDiv
                    title={"Order Id"}
                    value={item?.paymentDetails?.razorpay_order_id}
                  />
                </>
              ) : null}
              <FlexDiv title={"Total MRP"} value={totalMRP} />
              <FlexDiv title={"Total Discount"} value={totalMRPDiscount} />
              <FlexDiv
                title={"Coupon Discount"}
                value={item?.checkoutData?.discountAmount}
              />
              <FlexDiv title={"Total Amt Paid"} value={totalAmountToBePaid} />
            </div>
          }
        />
      </div>

      <DialogBox
        title={
          "Are you sure the return process for the user completed successfully."
        }
        open={returnDialogBox}
        setOpen={setReturnDialogBox}
        agreeFunc={completeReturnProcess}
      />
    </div>
  );
};

export default ReturnedOrders;
