import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { MdNearbyError } from "react-icons/md";
import { OutlineButton } from "../../../components/Button";
import TextComp from "../../../components/TextComp";
import { Colors } from "../../../constants/Colors";

function InvalidCoupon({ setInvalidCoupon, setOpenCouponModal }) {
  return (
    <div>
      <div className="bg-white p-2">
        <div className="flex flex-col items-end m-4">
          <IoCloseOutline
            color={Colors.black}
            size={40}
            className="cursor-pointer hover:p-1 duration-500"
            onClick={() => setInvalidCoupon(false)}
          />
        </div>

        <div className="flex flex-col items-center m-10">
          <MdNearbyError size={100} color={Colors.red} />
          <TextComp
            text="INVALID COUPON"
            customStyle={"mt-2 text-lg tracking-widest text-red"}
          />
          <TextComp
            text={
              "Sorry, this coupon isn't valid for the items in your cart. Please review the coupon's terms and conditions."
            }
            customStyle={"text-xs tracking-wide text-darkGray mt-5 text-center"}
          />
          <div className="mt-5 w-full">
            <OutlineButton
              btnText={"TRY ANOTHER"}
              customStyle={"text-darkGray border-darkGray"}
              onPress={() => {
                setInvalidCoupon(false);
                setOpenCouponModal(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvalidCoupon;
