import React from "react";
import Dialog from "@mui/material/Dialog";

import TextComp from "../TextComp";

function DialogBox({ open, setOpen, title, agreeFunc }) {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="m-5">
        <TextComp text={title} customStyle={"mb-5"} />

        <div className="flex justify-end items-center text-xs font-light">
          <div
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
            }}
          >
            <TextComp text={"CANCEL"} customStyle={"cursor-pointer text-red"} />
          </div>

          <div onClick={agreeFunc}>
            <TextComp
              text={"YES"}
              customStyle={
                "ml-5 cursor-pointer bg-black text-white px-5 py-2 rounded"
              }
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default DialogBox;
