import React from "react";
import NavbarAndFooterComp from "../../../components/NavbarAndFooterComp";
import { PageHeader } from "../../../components/PageHeader";

function AboutGeniease() {
  return (
    <NavbarAndFooterComp
      children={
        <div>
          <PageHeader text={"About US"} />

          <div className="py-10 mx-5 md:px-28 lg:px-40 lg:pt-20">
            <Body
              text={
                "Welcome to geniease, where style begins anew. Born from a passion for fashion and a desire to innovate, we are a fresh and dynamic brand founded in 2021 to redefine the fashion landscape. At geniease, we believe in embracing individuality and empowering self-expression through our unique designs. Join us on this exciting journey as we create trends, break boundaries, and inspire confidence in every wearer."
              }
            />

            <Body
              text={
                "Our mission is to empower self-expression through personalized fashion experiences, where every stitch and detail reflects a distinct personality. Discover the essence of chic, the allure of elegance, and the joy of fashion with geniease."
              }
            />
          </div>
        </div>
      }
    />
  );
}

function Body({ text }) {
  return (
    <div className="text-sm m-4 font-light tracking-widest mx-10">
      <p>{text}</p>
    </div>
  );
}

export default AboutGeniease;
