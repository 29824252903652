import React from "react";
import CustomDrawer from "../../Drawer";
import SingleProductCheckout from "../../../screens/Bag/SingleProductCheckout";

function SingleCheckoutDrawer({
  showSingleCheckoutDrawer,
  setShowSingleCheckoutDrawer,
}) {
  const toggleDrawer = (value, state) => {
    state(value);
  };

  return (
    <CustomDrawer
      position={"right"}
      open={showSingleCheckoutDrawer}
      toggleDrawerFunc={toggleDrawer}
      children={
        <SingleProductCheckout
          setShowSingleCheckoutDrawer={setShowSingleCheckoutDrawer}
        />
      }
      state={setShowSingleCheckoutDrawer}
    />
  );
}

export default SingleCheckoutDrawer;
