import {
  errorToastify,
  infoToastify,
  successToastify,
} from "../../../../components/Toastify";
import { EnvConfig } from "../../../../config/EnvConfig";
import {
  RoundOffFloatIntoTwo,
  totalAmtToBePaidCalculation,
  totalMRPCalculation,
  totalMRPDiscountCalculation,
} from "../../../../config/PriceCalculation";
import { RoutesFetch } from "../../../../config/RoutesFetch";
import { Colors } from "../../../../constants/Colors";
import { ErrorTexts } from "../../../../constants/ErrorTexts";
import { InfoTexts } from "../../../../constants/InfoTexts";
import { SuccessTexts } from "../../../../constants/SuccessTexts";
import Logo from "../../../../assets/logo/Logo-black.JPG";
import { setBagData } from "../../../../config/Redux/BagData";

///calculation of bag ////

export const bagCalculation = ({
  setTotalMRP,
  setTotalMRPDiscount,
  setTotalAmountToBePaid,
  bagData,
  discountAmount,
  setData = false,
}) => {
  let totalMRPVariable = 0;
  let totalMRPDiscountVariable = 0;
  let freeProduct = false;

  for (let i = 0; i < bagData?.length; i++) {
    if (bagData[i]?.freeProduct !== true) {
      totalMRPVariable = totalMRPCalculation({
        AlreadyAddedAmt: totalMRPVariable,
        productPrice:
          bagData[i]?.variants?.compare_at_price || bagData[i]?.variants?.price,
        quantity: bagData[i].quantity,
      });

      totalMRPDiscountVariable = totalMRPDiscountCalculation({
        AlreadyAddedAmt: totalMRPDiscountVariable,
        productPrice: bagData[i]?.variants?.price,
        productComparePrice:
          bagData[i]?.variants?.compare_at_price || bagData[i]?.variants?.price,
        quantity: bagData[i].quantity,
      });
    } else {
      freeProduct = true;
    }
  }

  const totalAmountToBePaid =
    discountAmount === null || discountAmount === 0 || freeProduct === true
      ? RoundOffFloatIntoTwo(
          totalAmtToBePaidCalculation({
            totalMRPVariable,
            totalMRPDiscountVariable,
          })
        )
      : RoundOffFloatIntoTwo(
          totalAmtToBePaidCalculation({
            totalMRPVariable,
            totalMRPDiscountVariable,
          }) - discountAmount
        );

  if (setData === true) {
    setTotalMRP(RoundOffFloatIntoTwo(totalMRPVariable));
    setTotalMRPDiscount(RoundOffFloatIntoTwo(totalMRPDiscountVariable));
    setTotalAmountToBePaid(totalAmountToBePaid);
  }

  return totalAmountToBePaid;
};

///////  Payment and create order functions ////////

export const createOrder = (
  checkoutData,
  paymentMethod,
  paymentStatus,
  paymentDetails,
  discountData,
  dispatch,
  setDrawer,
  setOrderSuccess,
  { setLoading, setBagDataFromParent, totalAmountToBePaid }
) => {
  setLoading(true);

  RoutesFetch({
    method: "post",
    url: "/createOrder",
    data: {
      checkoutId: checkoutData?.checkoutId,
      paymentMethod,
      paymentStatus,
      paymentDetails: paymentDetails,
      deliveryStatus: "ordered",
      totalAmountToBePaid,
      discountData,
    },
  })
    .then((res) => {
      if (res.msg === "fail") {
        paymentMethod === "cod"
          ? errorToastify(ErrorTexts.sww)
          : infoToastify(ErrorTexts.paymentDoneButOrderNotSave);
      } else if (res.msg === "codNotAllowed") {
        infoToastify(InfoTexts.codNotAllowed);
      } else {
        // successToastify(SuccessTexts.orderPlaced);
        setOrderSuccess(true);
        setBagDataFromParent(null);
        dispatch(setBagData([]));
        setDrawer(false);
        RoutesFetch({
          method: "post",
          url: "/deleteBagProducts",
        })
          .then(() => {})
          .catch(() => {});
      }
    })
    .catch(() => {
      paymentMethod === "cod"
        ? errorToastify(ErrorTexts.sww)
        : infoToastify(ErrorTexts.paymentDoneButOrderNotSave);
    })
    .finally(() => {
      setLoading(false);
    });
};

export const onlinePayment = (
  checkoutData,
  discountData,
  dispatch,
  setDrawer,
  setOrderSuccess,
  { setLoading, setBagDataFromParent, totalAmountToBePaid, userData }
) => {
  RoutesFetch({
    method: "get",
    url: "/payment",
    params: {
      price: totalAmountToBePaid,
    },
  })
    .then((res) => {
      if (res.msg === "fail") {
        errorToastify(ErrorTexts.sww);
      } else {
        const options = {
          key: EnvConfig.razorpayKeyId,
          name: "geniease",
          image: Logo,
          order_id: res?.orderId,
          prefill: {
            name: userData?.firstName + userData?.lastName,
            email: userData?.email,
            contact: userData?.mobileNumber,
          },
          theme: {
            color: Colors.black,
          },

          handler: function (response) {
            createOrder(
              checkoutData,
              "online",
              "success",
              response,
              discountData,
              dispatch,
              setDrawer,
              setOrderSuccess,
              {
                setLoading,
                setBagDataFromParent,
                totalAmountToBePaid,
              }
            );
          },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();

        rzp1.on("payment.failed", function (response) {
          RoutesFetch({
            method: "post",
            url: "/createPaymentFailed",
            data: {
              checkoutId: checkoutData?.checkoutId,
              paymentDetails: response,
            },
          })
            .then(() => {})
            .catch(() => {})
            .finally(() => {
              errorToastify(ErrorTexts.paymentFailed);
              setLoading(false);
            });
        });
      }
    })
    .catch((err) => {
      errorToastify(ErrorTexts.sww);
    });
};

export const proceedToCheckout = ({
  setLoading,
  deliveryAddress,
  fromSingleCheckout,
  bagData,
  setUpdateBagData,
  updateBagData,
  setBagDataFromParent,
  userData,
  paymentType,
  couponCode,
  dispatch,
  setDrawer,
  setOrderSuccess,
}) => {
  if (deliveryAddress === null) {
    errorToastify(ErrorTexts.selectAddress);
  } else {
    setLoading(true);

    RoutesFetch({
      method: "get",
      url:
        fromSingleCheckout === true
          ? "/getProductDetailsForSingleCheckout"
          : "/getProductToBag",
    })
      .then(async (res) => {
        if (res.msg === "fail") {
          errorToastify(ErrorTexts.sww);
        } else {
          let bag = null;
          let discountData = null;
          let discountAmount = 0;

          if (couponCode === "") {
            bag = res?.bagData;
          } else {
            await RoutesFetch({
              method: "post",
              url: "/validateCouponDiscount",
              data: {
                couponCode,
                bagData,
              },
            })
              .then((response) => {
                discountData = response?.couponData?.discountData;
                discountAmount = response?.couponData?.discountAmount;
                bag = response?.couponData?.bagData;
              })
              .catch(() => {
                return errorToastify(ErrorTexts.sww);
              });
          }

          let productDetails = res?.productData?.products;

          let mergedData = [];

          for (let i = 0; i < bag.length; i++) {
            for (let j = 0; j < productDetails.length; j++) {
              if (bag[i].productId === productDetails[j].id) {
                mergedData.push({ ...bag[i], ...productDetails[j] });

                productDetails.push(productDetails[j]);
                productDetails.splice(j, 1);
                break;
              }
            }
          }

          let allProductAreInStock = true;

          for (let i = 0; i < mergedData.length; i++) {
            if (
              mergedData[i]?.quantity >
              mergedData[i]?.variants?.inventory_quantity
            ) {
              allProductAreInStock = false;
              break;
            }
          }

          const totalAmountToBePaid = bagCalculation({
            bagData,
            discountAmount,
          });

          if (allProductAreInStock === true) {
            RoutesFetch({
              method: "post",
              url: "/createCheckout",
              data: {
                address: deliveryAddress,
                productDetails: bagData,
                discount: discountData,
                discountAmount,
              },
            })
              .then((res) => {
                if (res.msg === "fail") {
                  errorToastify(ErrorTexts.sww);
                } else {
                  if (paymentType === "cod") {
                    createOrder(
                      res?.checkoutData,
                      "cod",
                      "pending",
                      null,
                      discountData,
                      dispatch,
                      setDrawer,
                      setOrderSuccess,
                      {
                        setLoading,
                        setBagDataFromParent,
                        totalAmountToBePaid,
                      }
                    );
                  } else {
                    onlinePayment(
                      res?.checkoutData,
                      discountData,
                      dispatch,
                      setDrawer,
                      setOrderSuccess,
                      {
                        totalAmountToBePaid,
                        setLoading,
                        setBagDataFromParent,
                        userData,
                      }
                    );
                  }
                }
              })
              .catch((err) => {
                errorToastify(ErrorTexts.sww);
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            setUpdateBagData(updateBagData + 1);
            infoToastify(InfoTexts.someProductOutOfStock);
          }
        }
      })
      .catch((err) => {
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        setLoading(false);
      });
  }
};

///// discount function ////

export const validateCouponBeforeApply = ({
  setLoading,
  bagData,
  setDiscountData,
  setDiscountAmount,
  setInvalidCoupon,
  setBagDataFromParent,
  couponCode,
  setCouponCode,
}) => {
  setLoading(true);

  RoutesFetch({
    method: "post",
    url: "/validateCouponDiscount",
    data: {
      couponCode,
      bagData,
    },
  })
    .then((response) => {
      if (response.msg === "fail") {
        setCouponCode("");
        setDiscountAmount(null);
        errorToastify(ErrorTexts.sww);
      } else if (response.msg === "invalid") {
        setCouponCode("");
        setDiscountAmount(null);
        setInvalidCoupon(true);
      } else {
        setDiscountData(response?.couponData?.discountData);
        setDiscountAmount(response?.couponData?.discountAmount);
        setBagDataFromParent(response?.couponData?.bagData);
      }
    })
    .catch(() => {
      setCouponCode("");
      setDiscountAmount(null);
      errorToastify(ErrorTexts.sww);
    })
    .finally(() => {
      setLoading(false);
    });
};

///// adress function ////

export const getAddress = ({ setAddressLoading, setDeliveryAddress }) => {
  setAddressLoading(true);

  RoutesFetch({
    method: "get",
    url: "/getDefaultAddress",
  })
    .then((res) => {
      if (res.msg === "fail") {
        errorToastify(ErrorTexts.sww);
      } else {
        setDeliveryAddress(res?.addressData);
      }
    })
    .catch(() => {
      errorToastify(ErrorTexts.sww);
    })
    .finally(() => {
      setAddressLoading(false);
    });
};
