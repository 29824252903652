import React, { useEffect, useState } from "react";
import NavbarAndFooterComp from "../../../components/NavbarAndFooterComp";
import SelectInput from "../../../components/TextInput/SelectInput";
import { DeliveryStatuses } from "../../../constants/DeliveryStatuses";
import { RoutesFetch } from "../../../config/RoutesFetch";
import { ErrorTexts } from "../../../constants/ErrorTexts";
import RouteWaiter from "../../../components/RouteWaiter";
import CustomAccordion from "../../../components/CustomAccordion";
import TextComp from "../../../components/TextComp";
import InfiniteScrollComp from "../../../components/InfiniteScrollComp";
import {
  DiscountCalculation,
  RoundOffFloatIntoTwo,
  totalAmtToBePaidCalculation,
  totalMRPCalculation,
  totalMRPDiscountCalculation,
} from "../../../config/PriceCalculation";
import Button from "../../../components/Button";
import DialogBox from "../../../components/DialogBox";
import { errorToastify, successToastify } from "../../../components/Toastify";
import { SuccessTexts } from "../../../constants/SuccessTexts";
import { bagCalculation } from "../../Bag/checkoutCommonFlow/CheckoutFunctions";
import DisplayProducts from "../../ProfileMenu/MyAccount/MyOrders/DisplayProducts";

function AllOrders() {
  const [filter, setFilter] = useState("ordered");
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState(null);

  return (
    <NavbarAndFooterComp
      children={
        <div className="mx-5 md:mx-10 py-10">
          <SelectInput
            label={"Search by order status"}
            values={DeliveryStatuses}
            id="title"
            onChange={(e) => setFilter(e.target.value)}
            defaultValue={"ordered"}
          />

          <div>
            <InfiniteScrollComp
              url="/getAllOrder"
              responseDataName="orderData"
              params={{ orderStatus: filter }}
              setLoading={setLoading}
              setErrorText={setErrorText}
              data={orderData}
              setData={setOrderData}
              valueOfUseEffectArray={[filter]}
              children={
                <RouteWaiter
                  loading={loading}
                  error={errorText}
                  data={orderData}
                  children={
                    <div>
                      {orderData?.map((item, index) => (
                        <CardsComp
                          item={item}
                          key={index}
                          setLoading={setLoading}
                          setOrderData={setOrderData}
                          orderData={orderData}
                          index={index}
                        />
                      ))}
                    </div>
                  }
                />
              }
            />
          </div>
        </div>
      }
    />
  );
}

const CardsComp = ({ item, setLoading, setOrderData, orderData, index }) => {
  const [totalMRP, setTotalMRP] = useState(null);
  const [totalMRPDiscount, setTotalMRPDiscount] = useState(null);
  const [totalAmountToBePaid, setTotalAmountToBePaid] = useState(null);

  const [updateDeliveryStatus, setUpdateDeliveryStatus] = useState(
    item?.deliveryStatus
  );
  const [updatePaymentStatus, setUpdatePaymentStatus] = useState(
    item?.paymentStatus
  );

  const [updateDeliveryStatusDialogBox, setUpdateDeliveryStatusDialogBox] =
    useState(false);
  const [updatePaymentStatusDialogBox, setUpdatePaymentStatusDialogBox] =
    useState(false);

  useEffect(() => {
    bagCalculation({
      setTotalMRP,
      setTotalMRPDiscount,
      setTotalAmountToBePaid,
      bagData: item?.checkoutData?.productDetails,
      discountAmount: item?.checkoutData?.discountAmount,
      setData: true,
    });
  }, []);

  const FlexDiv = ({ title, value }) => {
    return (
      <div className="flex flex-row items-center justify-between text-sm tracking-wide font-medium mb-2">
        <TextComp text={title} />
        <TextComp text={value} />
      </div>
    );
  };

  const updateDeliveryStatusFunc = () => {
    setLoading(true);

    RoutesFetch({
      method: "post",
      url: "/updateDeliveryStatus",
      data: {
        orderId: item?._id,
        deliveryStatus: updateDeliveryStatus,
      },
    })
      .then((res) => {
        if (res?.msg === "fail") {
          errorToastify(ErrorTexts.sww);
        } else {
          orderData.splice(index, 1);

          setOrderData([...orderData]);
          successToastify(SuccessTexts.deliveryStatusUpdated);
        }
      })
      .catch(() => {
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        setUpdateDeliveryStatusDialogBox(false);
        setLoading(false);
      });
  };

  const updatePaymentStatusFunc = () => {
    setLoading(true);

    RoutesFetch({
      method: "post",
      url: "/updatePaymentStatus",
      data: {
        orderId: item?._id,
      },
    })
      .then((res) => {
        if (res?.msg === "fail") {
          errorToastify(ErrorTexts.sww);
        } else {
          setUpdatePaymentStatus("success");
          successToastify(SuccessTexts.paymentStatusUpdated);
        }
      })
      .catch(() => {
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        setUpdatePaymentStatusDialogBox(false);
        setLoading(false);
      });
  };

  return (
    <div className="bg-white shadow my-10 py-5 px-5 cursor-pointer rounded">
      <div className="py-2">
        <CustomAccordion
          title={"User Details"}
          details={
            <div>
              {!item?.userData?.firstName ? null : (
                <FlexDiv
                  title={"Name"}
                  value={`${item?.userData?.firstName} ${item?.userData?.lastName}`}
                />
              )}
              {!item?.userData?.email ? null : (
                <FlexDiv title={"Email"} value={`${item?.userData?.email}`} />
              )}
              <FlexDiv
                title={"Mobile Number"}
                value={`${item?.userData?.mobileNumber}`}
              />
            </div>
          }
        />
      </div>

      <div className="py-2">
        <CustomAccordion
          title={"Product Details"}
          details={
            <div>
              <FlexDiv
                title={"Checkout Id"}
                value={item?.checkoutData?.checkoutId}
              />

              <FlexDiv title={"Order Id"} value={item?._id} />

              {item?.checkoutData?.productDetails?.map((productItem, index) => (
                <DisplayProducts
                  key={index}
                  productItem={productItem}
                  orderData={item}
                />
              ))}
            </div>
          }
        />
      </div>

      <div className="py-2">
        <CustomAccordion
          title={"Delivery Details"}
          details={
            <div>
              <TextComp
                text={`${item?.checkoutData?.address?.address1}, ${item?.checkoutData?.address?.address2}, ${item?.checkoutData?.address?.city}, ${item?.checkoutData?.address?.province}, ${item?.checkoutData?.address?.zip}`}
                customStyle={"font-light tracking-wider"}
              />
              <div className="my-5">
                <FlexDiv
                  title={"Name"}
                  value={`${item?.checkoutData?.address?.first_name} ${item?.checkoutData?.address?.last_name}`}
                />
                <FlexDiv
                  title={"Contact Number"}
                  value={`${item?.checkoutData?.address?.phone}`}
                />
                <FlexDiv
                  title={"Delivery Status"}
                  value={`${item?.deliveryStatus}`}
                />
                {item?.deliveryStatus !== "delivered" ? (
                  <div className="my-5">
                    <SelectInput
                      label={"Update order status"}
                      values={DeliveryStatuses}
                      id="demo-controlled-open-select"
                      onChange={(e) => setUpdateDeliveryStatus(e.target.value)}
                      defaultValue={updateDeliveryStatus}
                    />
                    <Button
                      btnText={"UPDATE DELIVERY STATUS"}
                      customStyle={
                        item?.deliveryStatus === updateDeliveryStatus
                          ? "my-2 bg-darkGray border-darkGray"
                          : "my-2"
                      }
                      onPress={() =>
                        item?.deliveryStatus === updateDeliveryStatus
                          ? null
                          : setUpdateDeliveryStatusDialogBox(true)
                      }
                    />
                  </div>
                ) : null}
              </div>
            </div>
          }
        />
      </div>

      <div className="py-2">
        <CustomAccordion
          title={"Pricing Details"}
          details={
            <div>
              <FlexDiv title={"Payment Type"} value={item?.paymentMethod} />
              <FlexDiv title={"Payment Status"} value={updatePaymentStatus} />

              {item?.paymentDetails !== null ? (
                <>
                  <FlexDiv
                    title={"Payment Id"}
                    value={item?.paymentDetails?.razorpay_payment_id}
                  />
                  <FlexDiv
                    title={"Order Id"}
                    value={item?.paymentDetails?.razorpay_order_id}
                  />
                </>
              ) : null}
              <FlexDiv title={"Total MRP"} value={totalMRP} />
              <FlexDiv title={"Total Discount"} value={totalMRPDiscount} />
              <FlexDiv
                title={"Coupon Discount"}
                value={item?.checkoutData?.discountAmount}
              />
              <FlexDiv title={"Total Amt Paid"} value={totalAmountToBePaid} />

              {updatePaymentStatus !== "success" ? (
                <Button
                  btnText={"PAYMENT COMPLETED"}
                  customStyle={"my-2"}
                  onPress={() => setUpdatePaymentStatusDialogBox(true)}
                />
              ) : null}
            </div>
          }
        />
      </div>

      <DialogBox
        title={
          "Confirming the update of delivery status. Are you sure you'd like to proceed?"
        }
        open={updateDeliveryStatusDialogBox}
        setOpen={setUpdateDeliveryStatusDialogBox}
        agreeFunc={updateDeliveryStatusFunc}
      />

      <DialogBox
        title={
          "Kindly ensure that the payment for this order has been completed before proceeding."
        }
        open={updatePaymentStatusDialogBox}
        setOpen={setUpdatePaymentStatusDialogBox}
        agreeFunc={updatePaymentStatusFunc}
      />
    </div>
  );
};

export default AllOrders;
