import React, { useState } from "react";
import { MobileTextInput } from "../../../components/TextInput";
import Button from "../../../components/Button";
import TextComp from "../../../components/TextComp";
import * as yup from "yup";
import { RoutesFetch } from "../../../config/RoutesFetch";
import { errorToastify } from "../../../components/Toastify";
import { ErrorTexts, ErrorDotMessage } from "../../../constants/ErrorTexts";

function MobileNumberScreen({
  setMobileNumber,
  mobileNumber,
  setLoading,
  setOtpSent,
  mainDivCustomStyle,
}) {
  const [errorText, setErrorText] = useState("");

  const sendOtp = () => {
    const mobileNumberValidation = yup.object().shape({
      mobileNumber: yup
        .string()
        .required("Please enter a valid mobile number")
        .matches(`^[0-9]+$`, "Please enter a valid mobile number")
        .min(10, "Please enter a valid mobile number")
        .max(10, "Please enter a valid mobile number"),
    });

    mobileNumberValidation
      .validate({
        mobileNumber,
      })
      .then(() => {
        setLoading(true);
        setErrorText("");

        RoutesFetch({
          method: "post",
          url: "/loginWithMobileNumber",
          data: {
            mobileNumber: `+91${mobileNumber}`,
          },
        })
          .then((response) => {
            if (response.msg === "fail") {
              errorToastify(ErrorTexts.otpsentfail);
            } else {
              setOtpSent(true);
            }
          })
          .catch((err) => {
            errorToastify(ErrorTexts.sww);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        setErrorText(ErrorDotMessage(err));
      });
  };

  return (
    <div
      className={`w-full md:w-1/2 lg:w-1/2 flex flex-col items-center ${mainDivCustomStyle}`}
    >
      <div className="mt-5 lg:mt-10 flex flex-row items-end">
        <TextComp
          text={"Login or Signup"}
          customStyle={"text-xs tracking-wider"}
        />
      </div>

      <div className="mt-4 w-full">
        <MobileTextInput
          label={"Mobile Number"}
          value={mobileNumber}
          onChange={(e) => {
            setMobileNumber(e.target.value);
          }}
          error={errorText === "" ? false : true}
          helperText={errorText === "" ? null : errorText}
          inputProps={{ maxLength: 10 }}
        />
      </div>

      <div className="mt-5 w-full">
        <Button btnText={"CONTINUE"} onPress={sendOtp} />
      </div>
    </div>
  );
}

export default MobileNumberScreen;
