import React from "react";
import TextComp from "../../components/TextComp";
import { FiPhone } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
// import { FaRegHandPointRight } from "react-icons/fa6";

import { ImpValues } from "../../constants/ImpValues";
import { Colors } from "../../constants/Colors";
import { Links } from "../../constants/Links";

import { useNavigate } from "react-router-dom";

function Contact() {
  const navigate = useNavigate();

  return (
    <div className="my-2 mx-10 lg:mx-20">
      <TextComp
        text={"CONTACT US"}
        customStyle={"text-2xl font-medium tracking-widest"}
      />

      <div className="my-10">
        <div className="mb-10">
          <a
            href={`tel:${ImpValues.contactNumber}`}
            className="flex flex-row items-center cursor-pointer"
          >
            <FiPhone size={18} />
            <TextComp
              text={`CALL US ${ImpValues.contactNumber}`}
              customStyle={"text-xs underline font-semibold ml-2"}
            />
          </a>
          <TextComp
            text={
              "Our Client Advisors are available Monday through Saturday, 9:00AM - 11:00PM and Sunday, 10:00AM - 9:00PM, excluding holidays."
            }
            customStyle={"text-xs font-light mt-2"}
          />
        </div>

        <div className="mb-10">
          <a
            href={Links.whatsAppLink}
            target="_blank"
            className="flex flex-row items-center cursor-pointer"
          >
            <FaWhatsapp size={18} />
            <TextComp
              text={`WHATSAPP US`}
              customStyle={"text-xs underline font-semibold ml-2 "}
            />
          </a>
          <TextComp
            text={
              "Our Client Advisors are available to answer your WhatsApp messages Monday through Saturday, 9:00AM - 8:00PM, and Sunday 10:00AM – 7:00PM, excluding holidays."
            }
            customStyle={"text-xs font-light mt-2"}
          />
        </div>

        <div className="mb-10">
          <div className="flex flex-row items-center">
            <GoDotFill size={18} color={Colors.yellow} />
            <TextComp
              text={`LIVE CHAT`}
              customStyle={
                "text-xs underline font-light tracking-wide ml-2 cursor-not-allowed"
              }
            />
          </div>
          <TextComp
            text={
              "Our Client Advisors are available to chat Monday through Saturday, 9:00AM - 11:00PM and Sunday, 10:00AM - 9:00PM, excluding holidays. The option to chat will become active during these hours once an advisor is available."
            }
            customStyle={"text-xs font-light mt-2"}
          />
        </div>
      </div>

      <div>
        <TextComp
          text={"Do you need further assistance?"}
          customStyle={"text-lg font-medium tracking-wide mb-4"}
        />
        <div
          className="flex flex-row items-center"
          onClick={() => navigate("/contact-us")}
        >
          <TextComp
            text={"Get in Contact with Us"}
            customStyle={
              "text-thin text-sm border-b pb-0.2 mr-2 cursor-pointer w-fit"
            }
          />
          {/* <FaRegHandPointRight size={18} /> */}
        </div>
      </div>
    </div>
  );
}

export default Contact;
