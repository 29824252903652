import React from "react";
import NavbarAndFooterComp from "../../../components/NavbarAndFooterComp";
import TextComp from "../../../components/TextComp";

import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../../components/PageHeader";
import { useSelector } from "react-redux";

function MyAccount() {
  const userData = useSelector((state) => state?.userData?.data);

  const navigate = useNavigate();

  const CardsComp = ({ heading, text, link }) => {
    return (
      <div
        onClick={() => navigate(link)}
        className="col-span-1 bg-white shadow my-10 mx-10 md:my-5 md:mx-5 lg:mx-10 py-10 px-5 flex flex-col items-center cursor-pointer rounded"
      >
        <TextComp
          text={heading}
          customStyle={"text-sm font-medium border-b w-fit"}
        />
        <TextComp text={text} customStyle={"text-xs text-darkGray pt-4"} />
      </div>
    );
  };

  return (
    <NavbarAndFooterComp
      children={
        <div className="flex flex-col items-center">
          <PageHeader
            text={`WELCOME ${userData?.firstName ? userData?.firstName : ""} ${
              userData?.lastName ? userData?.lastName : ""
            }`}
          />

          <div className="md:grid grid-cols-2 w-full">
            <CardsComp
              heading={"ACCOUNT SETTINGS"}
              text={"Manage profile and preferences."}
              link={"/accountsettings"}
            />

            <CardsComp
              heading={"MY ORDERS"}
              text={"Manage and edit your orders."}
              link={"/myorders"}
            />

            <CardsComp
              heading={"MY CUSTOMISATION"}
              text={"Product orders created by you."}
              link={"/customisationorders"}
            />

            <CardsComp
              heading={"SAVED ITEMS"}
              text={"All your favorite pieces in one beautiful place."}
              link={"/saveditems"}
            />

            <CardsComp
              heading={"ADDRESS BOOK"}
              text={"Manage shipping & billing addresses."}
              link={"/addressbook"}
            />

            {userData?.admin === true ? (
              <CardsComp
                heading={"Admin Panel"}
                text={"Manage all your users data over here."}
                link={"/adminpanel"}
              />
            ) : null}
          </div>
        </div>
      }
    />
  );
}

export default MyAccount;
