import React from "react";
import DescriptionText from "../DescriptionText";

function PaymentOptions() {
  return (
    <div>
      <DescriptionText text={"Pay on delivery available."} />
    </div>
  );
}

export default PaymentOptions;
