import React, { useState } from "react";
import { useFormik } from "formik";

// import SelectInput from "../TextInput/SelectInput";
import { MobileTextInput, OutlineTextInput } from "../TextInput";
import Button from "../Button";
import TextComp from "../TextComp";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RoutesFetch } from "../../config/RoutesFetch";
import { errorToastify, successToastify } from "../Toastify";
import { ErrorTexts } from "../../constants/ErrorTexts";
import { SuccessTexts } from "../../constants/SuccessTexts";
import Loader from "../Loader";
import { setData } from "../../config/Redux/UserData";

function UserDetails() {
  const userData = useSelector((state) => state?.userData?.data);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: userData?.firstName === undefined ? "" : userData?.firstName,
      lastName: userData?.lastName === undefined ? "" : userData?.lastName,
      email: userData?.email === undefined ? "" : userData?.email,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Please enter a valid name"),
      lastName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Please enter a valid name"),
      email: Yup.string()
        .email("Please enter a valid email address")
        .required("Please enter a valid email address"),
    }),
    onSubmit: (values) => {
      setLoading(true);

      RoutesFetch({
        method: "post",
        url: "/updateUserData",
        data: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
        },
      })
        .then((response) => {
          if (response.msg === "fail") {
            errorToastify(ErrorTexts.sww);
          } else {
            dispatch(setData(userData));
            successToastify(SuccessTexts.detailsUpdate);
          }
        })
        .catch((err) => {
          errorToastify(ErrorTexts.sww);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-2 mt-10">
          {/* <div className="col-span-1 mx-2">
          <SelectInput
            label={"Title"}
            values={["Mr.", "Mrs.", "Ms.", "I'd rather not say"]}
            id="title"
            onChange={formik.handleChange}
          />
        </div> */}

          <div className="col-span-1 mr-1">
            <OutlineTextInput
              label={"First Name *"}
              id="firstName"
              onChange={formik.handleChange}
              value={formik.values.firstName}
              error={
                formik.touched.firstName && formik.errors.firstName
                  ? true
                  : false
              }
              helperText={
                formik.touched.firstName && formik.errors.firstName
                  ? formik.errors.firstName
                  : null
              }
            />
          </div>

          <div className="col-span-1 ml-1">
            <OutlineTextInput
              label={"Last Name *"}
              id="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={
                formik.touched.lastName && formik.errors.lastName ? true : false
              }
              helperText={
                formik.touched.lastName && formik.errors.lastName
                  ? formik.errors.lastName
                  : null
              }
            />
          </div>
        </div>

        <div className="grid grid-cols-3 mt-5">
          <div className="col-span-1 mr-1">
            <OutlineTextInput
              label={"Country / Region"}
              disabled={true}
              defaultValue={"India"}
            />
          </div>

          <div className="col-span-2 ml-1">
            <OutlineTextInput
              label={"Email *"}
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && formik.errors.email ? true : false}
              helperText={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
            />
          </div>
        </div>

        <div>
          <TextComp
            text={"MY CREDENTIALS"}
            customStyle={"text-2xl tracking-widest font-light my-10"}
          />

          <MobileTextInput
            label={"Mobile Number"}
            disabled={true}
            defaultValue={userData?.mobileNumber}
          />
        </div>

        <div className="my-10 flex flex-col items-end ">
          <button className="w-full md:w-fit" type="submit">
            <Button btnText={"SAVE"} />
          </button>
        </div>
      </form>

      <Loader loading={loading} />
    </>
  );
}

export default UserDetails;
