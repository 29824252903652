import { toast } from "react-toastify";

function errorToastify(errText) {
  toast.error(errText, {
    position: "bottom-right",
  });
}

function infoToastify(errText) {
  toast.warn(errText, {
    position: "bottom-right",
  });
}

function successToastify(successText) {
  toast.success(successText, {
    position: "bottom-right",
  });
}

export { errorToastify, successToastify, infoToastify };
