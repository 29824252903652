import { Navigate, createBrowserRouter } from "react-router-dom";
import Home from "../../screens/Home";
import Login from "../../screens/Login";
import MyAccount from "../../screens/ProfileMenu/MyAccount";
import AccountSettings from "../../screens/ProfileMenu/MyAccount/AccountSettings";
import MyOrders from "../../screens/ProfileMenu/MyAccount/MyOrders";
import AddressBook from "../../screens/ProfileMenu/MyAccount/AddressBook";
import SavedItems from "../../screens/ProfileMenu/MyAccount/SavedItems";
import { AppChild } from "../../";
import ContactForm from "../../screens/Contact/ContactForm";
import PersonalDetais from "../../screens/Login/PersonalDetails";
import Customisation from "../../screens/Customisation";
import AboutGeniease from "../../screens/TheCompany/AboutGeniease";
import Terms from "../../screens/TheCompany/Terms";
import Policies from "../../screens/TheCompany/Policies";
import Faqs from "../../screens/TheCompany/Faqs";
import ProductView from "../../screens/ProductView";
import Loader from "../../components/Loader";
import NavbarAndFooterComp from "../../components/NavbarAndFooterComp";
import AdminPanel from "../../screens/AdminPanel";
import AllOrders from "../../screens/AdminPanel/AllOrders";
import Offers from "../../screens/AdminPanel/Offers";
import CreateCoupon from "../../screens/AdminPanel/Offers/CreateCoupon";
import OrderDetails from "../../screens/ProfileMenu/MyAccount/MyOrders/OrderDetails";
import ReturnedOrders from "../../screens/AdminPanel/ReturnedOrders";
import CustomisationOrders from "../../screens/ProfileMenu/MyAccount/CustomisationOrders";
import CustomisationRequest from "../../screens/AdminPanel/CustomisationRequest";
import Collections from "../../screens/Collections";
import PageNotFound from "../../components/BlankScreen/PageNotFound";

export const router = ({ userData, loading }) => {
  const LoaderScreen = () => {
    return <NavbarAndFooterComp children={<Loader loading={loading} />} />;
  };

  function AuthChecker({ screen }) {
    return loading === true ? (
      <LoaderScreen />
    ) : userData?.mobileNumberVerified === true ? (
      screen
    ) : (
      <Navigate to="/login" />
    );
  }

  function UserAlreadyAuthenticated({ screen }) {
    return loading === true ? (
      <LoaderScreen />
    ) : userData?.mobileNumberVerified === true ? (
      <Navigate to="/myaccount" />
    ) : (
      screen
    );
  }

  function AdminChecker({ screen }) {
    return loading === true ? (
      <LoaderScreen />
    ) : userData?.mobileNumberVerified === true && userData?.admin === true ? (
      screen
    ) : (
      <Navigate to="/login" />
    );
  }

  return createBrowserRouter([
    {
      path: "/",
      element: <AppChild />,
      children: [
        {
          path: "*",
          element: <PageNotFound />,
        },
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/login",
          element: <UserAlreadyAuthenticated screen={<Login />} />,
        },
        {
          path: "/myaccount",
          element: <AuthChecker screen={<MyAccount />} />,
        },
        {
          path: "/myorders",
          element: <AuthChecker screen={<MyOrders />} />,
        },
        {
          path: "/customisationorders",
          element: <AuthChecker screen={<CustomisationOrders />} />,
        },
        {
          path: "/orderdetails/:orderId",
          element: <AuthChecker screen={<OrderDetails />} />,
        },
        {
          path: "/accountsettings",
          element: <AuthChecker screen={<AccountSettings />} />,
        },
        {
          path: "/addressbook",
          element: <AuthChecker screen={<AddressBook />} />,
        },
        {
          path: "/saveditems",
          element: <SavedItems />,
        },
        {
          path: "/contact-us",
          element: <ContactForm />,
        },
        {
          path: "/personaldetails",
          element: <PersonalDetais />,
        },
        {
          path: "/customisation",
          element: <Customisation />,
        },
        {
          path: "/aboutus",
          element: <AboutGeniease />,
        },
        {
          path: "/terms",
          element: <Terms />,
        },
        {
          path: "/policies",
          element: <Policies />,
        },
        {
          path: "/faqs",
          element: <Faqs />,
        },
        {
          path: "/product/:productId",
          element: <ProductView />,
        },
        {
          path: "/collections/:collectionId",
          element: <Collections />,
        },
        {
          path: "adminpanel",
          element: <AdminChecker screen={<AdminPanel />} />,
        },
        {
          path: "allorders",
          element: <AdminChecker screen={<AllOrders />} />,
        },
        {
          path: "adminOffers",
          element: <AdminChecker screen={<Offers />} />,
        },
        {
          path: "adminCreateCoupon",
          element: <AdminChecker screen={<CreateCoupon />} />,
        },
        {
          path: "returnedOrders",
          element: <AdminChecker screen={<ReturnedOrders />} />,
        },
        {
          path: "customisationrequest",
          element: <AdminChecker screen={<CustomisationRequest />} />,
        },
      ],
    },
  ]);
};
