import React, { useState } from "react";
import NavbarAndFooterComp from "../../components/NavbarAndFooterComp";
import banner from "../../assets/banner/customBanner.png";
import TextComp from "../../components/TextComp";
import { Links } from "../../constants/Links";
import { Colors } from "../../constants/Colors";

import { AiOutlineInstagram, AiOutlineWhatsApp } from "react-icons/ai";
import { OutlineTextInput } from "../../components/TextInput";
import Button from "../../components/Button";
import CustomModal from "../../components/Modal";
import MobileVerification from "../../components/MobileVerification";
import { RoutesFetch } from "../../config/RoutesFetch";
import { errorToastify, successToastify } from "../../components/Toastify";
import { ErrorTexts } from "../../constants/ErrorTexts";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import OrderSuccessComp from "../Bag/OrderSuccessComp";
import { useLocation } from "react-router-dom";

function Customisation() {
  const userData = useSelector((state) => state?.userData?.data);
  const { state } = useLocation();

  const [loading, setLoading] = useState(false);
  const [showMobileVerificationModal, setShowMobileVerificationModal] =
    useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      productTitle: state?.productTitle || "",
      designDescription: "",
    },
    validationSchema: Yup.object({
      productTitle: Yup.string().required("Please enter a valid product title"),
      designDescription: Yup.string().required("Please enter your design idea"),
    }),
    onSubmit: (values) => {
      if (userData?.mobileNumberVerified === true) {
        setLoading(true);

        RoutesFetch({
          method: "post",
          url: "/createCustomisationOrder",
          data: {
            productTitle: values?.productTitle,
            designDescription: values?.designDescription,
          },
        })
          .then((response) => {
            if (response.msg === "fail") {
              errorToastify(ErrorTexts.sww);
            } else {
              formik.resetForm();
              setOrderSuccess(true);
            }
          })
          .catch((err) => {
            errorToastify(ErrorTexts.sww);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setShowMobileVerificationModal(true);
      }
    },
  });

  return (
    <NavbarAndFooterComp
      children={
        <div className="mb-10 md:mb-20">
          <div className="relative w-full flex justify-center">
            <img src={banner} className="" />
            <div className="absolute bottom-0 w-full py-5">
              <TextComp
                text={"We will do"}
                customStyle={
                  "text-2xl md:text-3xl lg:text-4xl font-semibold tracking-widest pb-2 w-full text-white text-center drop-shadow-lg"
                }
              />
              <TextComp
                text={"CUSTOMISATION"}
                customStyle={
                  "text-4xl md:text-6xl lg:text-8xl font-semibold tracking-widest w-full text-white text-center drop-shadow-lg"
                }
              />
            </div>
          </div>

          <div className="px-5 md:px-20 py-10">
            <TextComp
              text={"Customise your fashion on our station."}
              customStyle={"text-2xl tracking-widest font-light"}
            />

            <div className="py-3">
              <TextComp
                customStyle="text-xs tracking-wider text-darkGray font-light"
                text={" Directly message us on Instagram or WhatsApp"}
              />

              <div className="flex flex-row my-5">
                <a
                  className="bg-black text-white py-4 px-6 rounded-lg mr-8 flex items-center cursor-pointer"
                  href={Links.instagramLink}
                  target="_blank"
                >
                  <AiOutlineInstagram
                    size={24}
                    className="mr-2"
                    color={Colors.white}
                  />
                  <TextComp text={"Instagram"} />
                </a>

                <a
                  className="bg-black text-white py-4 px-6 rounded-lg mr-8 flex items-center cursor-pointer"
                  href={Links.whatsAppLink}
                  target="_blank"
                >
                  <AiOutlineWhatsApp
                    size={24}
                    className="mr-2"
                    color={Colors.white}
                  />
                  <TextComp text={"WhatsApp"} />
                </a>
              </div>

              <TextComp customStyle="text-xl text-black pb-1" text={"or"} />

              <TextComp
                customStyle={
                  "text-xs tracking-wider text-darkGray font-light pt-3"
                }
                text={"Fill the details below, for a ready response from us!"}
              />

              <form onSubmit={formik.handleSubmit}>
                <div className="mt-5">
                  <OutlineTextInput
                    label={"Enter product title *"}
                    id="productTitle"
                    value={formik.values.productTitle}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.productTitle && formik.errors.productTitle
                        ? true
                        : false
                    }
                    helperText={
                      formik.touched.productTitle && formik.errors.productTitle
                        ? formik.errors.productTitle
                        : null
                    }
                  />
                </div>

                <div className="mt-5">
                  <OutlineTextInput
                    label={"What is your design idea? *"}
                    multiline={true}
                    minRows={4}
                    maxRows={4}
                    id="designDescription"
                    value={formik.values.designDescription}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.designDescription &&
                      formik.errors.designDescription
                        ? true
                        : false
                    }
                    helperText={
                      formik.touched.designDescription &&
                      formik.errors.designDescription
                        ? formik.errors.designDescription
                        : null
                    }
                  />
                </div>

                <div className="mt-5">
                  <TextComp
                    text={`o State the product name or category you wish to customize.`}
                    customStyle={"text-xs text-darkGray tracking-wide"}
                  />
                  <TextComp
                    text={`o We offer customization services for products from other brands as well.`}
                    customStyle={"text-xs text-darkGray tracking-wide"}
                  />
                  <TextComp
                    text={`o A thorough discussion of all customization details will be conducted to ensure we meet your requirements.`}
                    customStyle={"text-xs text-darkGray tracking-wide"}
                  />
                </div>

                <button type="submit" className="mt-5 w-full">
                  <Button btnText={"SUBMIT"} />
                </button>
              </form>
            </div>
          </div>

          <CustomModal
            open={showMobileVerificationModal}
            setOpen={setShowMobileVerificationModal}
            children={
              <MobileVerification
                open={showMobileVerificationModal}
                setOpen={setShowMobileVerificationModal}
                setLoading={setLoading}
                loading={loading}
              />
            }
          />

          <CustomModal
            open={orderSuccess}
            setOpen={setOrderSuccess}
            atCenter={true}
            children={
              <OrderSuccessComp
                setOrderSuccess={setOrderSuccess}
                navigationRoute={"/customisationorders"}
                title="CUSTOMISATION ORDER"
                details="We will shortly contact you for the brief discussion."
                btnText="CUSTOMISATIONS"
              />
            }
          />
        </div>
      }
    />
  );
}

export default Customisation;
