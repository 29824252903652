import React, { useEffect, useState } from "react";

import SelectInput from "../../../../components/TextInput/SelectInput";
import CustomCheckbox from "../../../../components/Checkbox";
import { OutlineTextInput } from "../../../../components/TextInput";
import { useFormik } from "formik";
import Button from "../../../../components/Button";
import moment from "moment";
import * as Yup from "yup";
import { RoutesFetch } from "../../../../config/RoutesFetch";
import {
  errorToastify,
  successToastify,
} from "../../../../components/Toastify";
import { ErrorTexts } from "../../../../constants/ErrorTexts";
import { SuccessTexts } from "../../../../constants/SuccessTexts";
import TextComp from "../../../../components/TextComp";
import { discountTypes } from "../../../../constants/DiscountTypes";
import { MdAdd, MdCancel } from "react-icons/md";
import { Colors } from "../../../../constants/Colors";

function OffersForm({ setLoading, discountData, navigate }) {
  const [specificIds, setSpecificIds] = useState([]);
  const [freeProductIds, setFreeProductIds] = useState([]);
  const [customerIds, setCustomerIds] = useState([]);

  const formik = useFormik({
    initialValues: {
      couponName: discountData ? discountData.couponName : "",
      couponCode: discountData ? discountData.couponCode : "",
      discountIn: discountData ? discountData.discountIn : null,
      discountValue: discountData ? discountData.discountValue : "",
      onMinimumBagAmount: discountData ? discountData.onMinimumBagAmount : "",
      maxDiscountValueInPercentage: discountData
        ? discountData.maxDiscountValueInPercentage
        : "",
      numberOfProductsCombo: discountData
        ? discountData.numberOfProductsCombo
        : "",
      freeProductIdState: discountData ? discountData.freeProductIdState : "",
      specificType: discountData ? discountData.specificType : null,
      specificIdState: discountData ? discountData.specificIdState : "",
      customerIdsState: discountData ? discountData.customerIdsState : "",
      limit: discountData ? discountData.limit : "",
      couponUsedCount: discountData ? discountData.couponUsedCount : 0,
      limitToOnePerCustomer: discountData
        ? discountData.limitToOnePerCustomer
        : false,
      valid: discountData ? discountData.valid : false,
      showInList: discountData ? discountData.showInList : false,
      startDate: discountData
        ? discountData.startDate
        : moment(new Date()).format("DD-MM-YYYY"),
      endDate: discountData
        ? discountData.endDate
        : moment(new Date()).format("DD-MM-YYYY"),
      discountDescription: discountData ? discountData.discountDescription : "",
    },

    validationSchema: Yup.object({
      couponName: Yup.string().required("Please enter a coupon name"),
      couponCode: Yup.string().required("Please enter a coupon code"),
      discountIn: Yup.string().required("Please enter a discount type"),
      discountValue: Yup.string().required("Please enter a discount Value"),
      onMinimumBagAmount: Yup.string().required(
        "Please enter a minimum amount in bag"
      ),
      limit: Yup.string().required("Please enter a limit of coupon"),
      startDate: Yup.string().required("Please enter a start date"),
      endDate: Yup.string().required("Please enter a end date"),
      discountDescription: Yup.string().required(
        "Please enter a discount Description"
      ),
    }),

    onSubmit: (values) => {
      setLoading(true);

      RoutesFetch({
        method: "post",
        url: "/createDiscount",
        data: {
          couponName: values.couponName,
          couponCode: values.couponCode,
          discountIn: values.discountIn,
          discountValue: values.discountValue,
          onMinimumBagAmount: values.onMinimumBagAmount,
          maxDiscountValueInPercentage: values.maxDiscountValueInPercentage,
          numberOfProductsCombo: values.numberOfProductsCombo,
          freeProductIds: freeProductIds,
          specificType: values.specificType,
          specificIds: specificIds,
          customerIds: customerIds,
          limit: values.limit,
          couponUsedCount: values.couponUsedCount,
          limitToOnePerCustomer: values.limitToOnePerCustomer,
          valid: values.valid,
          showInList: values.showInList,
          startDate: values.startDate,
          endDate: values.endDate,
          discountDescription: values.discountDescription,
        },
      })
        .then((response) => {
          if (response.msg === "fail") {
            errorToastify(ErrorTexts.sww);
          } else {
            navigate(-1);
            successToastify(SuccessTexts.couponCreated);
          }
        })
        .catch((err) => {
          errorToastify(ErrorTexts.sww);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    setFreeProductIds([]);
    formik.resetForm({
      values: {
        ...formik.values,
        maxDiscountValueInPercentage: "",
        numberOfProductsCombo: "",
      },
    });
  }, [formik.values.discountIn]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="my-10">
        <div className="mb-5 flex flex-row items-center w-full">
          <div className="mr-5 w-full">
            <OutlineTextInput
              label={"Coupon Name"}
              id="couponName"
              value={formik.values.couponName}
              onChange={formik.handleChange}
              error={
                formik.touched.couponName && formik.errors.couponName
                  ? true
                  : false
              }
              helperText={
                formik.touched.couponName && formik.errors.couponName
                  ? formik.errors.couponName
                  : null
              }
            />
          </div>

          <div className="ml-5 w-full">
            <OutlineTextInput
              label={"Coupon Code"}
              id="couponCode"
              value={formik.values.couponCode}
              onChange={formik.handleChange}
              error={
                formik.touched.couponCode && formik.errors.couponCode
                  ? true
                  : false
              }
              helperText={
                formik.touched.couponCode && formik.errors.couponCode
                  ? formik.errors.couponCode
                  : null
              }
            />
          </div>
        </div>

        <div className="w-full mb-5">
          <OutlineTextInput
            label={"Coupon Description"}
            id="discountDescription"
            value={formik.values.discountDescription}
            onChange={formik.handleChange}
            multiline
            minRows={4}
            maxRows={4}
            error={
              formik.touched.discountDescription &&
              formik.errors.discountDescription
                ? true
                : false
            }
            helperText={
              formik.touched.discountDescription &&
              formik.errors.discountDescription
                ? formik.errors.discountDescription
                : null
            }
          />
        </div>

        <div className="mb-5 flex flex-row items-center w-full">
          <div className="mr-5 w-full">
            <SelectInput
              label={"Discount In"}
              values={discountTypes}
              id="discountIn"
              value={formik.values.discountIn}
              onChange={formik.handleChange("discountIn")}
            />
          </div>

          <div className="ml-5 w-full">
            <OutlineTextInput
              label={"Discount Value"}
              id="discountValue"
              value={formik.values.discountValue}
              onChange={formik.handleChange}
              error={
                formik.touched.discountValue && formik.errors.discountValue
                  ? true
                  : false
              }
              helperText={
                formik.touched.discountValue && formik.errors.discountValue
                  ? formik.errors.discountValue
                  : null
              }
            />
          </div>
        </div>

        <div className="mb-5">
          <OutlineTextInput
            label={"Minimum amount in bag"}
            id="onMinimumBagAmount"
            value={formik.values.onMinimumBagAmount}
            onChange={formik.handleChange}
          />
        </div>

        {formik.values.discountIn === "percentage" ? (
          <div className="mb-5">
            <OutlineTextInput
              label={"Max Discount Value (in percentage)"}
              id="maxDiscountValueInPercentage"
              value={formik.values.maxDiscountValueInPercentage}
              onChange={formik.handleChange}
            />
          </div>
        ) : null}

        {formik.values.discountIn === "combo" ? (
          <div className="mb-5">
            <OutlineTextInput
              label={"Number of products for combo"}
              id="numberOfProductsCombo"
              value={formik.values.numberOfProductsCombo}
              onChange={formik.handleChange}
            />
          </div>
        ) : null}

        {formik.values.discountIn === "free" ? (
          <>
            <div className="mb-5 flex flex-row items-center">
              <OutlineTextInput
                label={"Free Product Ids"}
                id="freeProductIdState"
                value={formik.values.freeProductIdState}
                onChange={formik.handleChange}
              />

              <div
                className="bg-black p-3.5 rounded ml-2"
                type="reset"
                onClick={() => {
                  if (formik.values.freeProductIdState !== "") {
                    setFreeProductIds([
                      ...freeProductIds,
                      formik.values.freeProductIdState,
                    ]);
                    formik.resetForm({
                      values: { ...formik.values, freeProductIdState: "" },
                    });
                  }
                }}
              >
                <MdAdd color={Colors.white} size={28} />
              </div>
            </div>

            {freeProductIds?.length === 0 ? null : (
              <div className="flex flex-wrap items-center mb-5">
                {freeProductIds?.map((item, index) => (
                  <div
                    key={index}
                    className="bg-lightGray rounded py-1 px-3 m-1 text-base tracking-wide flex items-center"
                  >
                    <TextComp text={item} />
                    <MdCancel size={18} color={Colors.black} className="ml-2" />
                  </div>
                ))}
              </div>
            )}
          </>
        ) : null}

        <div className="mb-5 w-full">
          <SelectInput
            label={"Specific Type"}
            values={[
              { label: "Collection ids", value: "collectionids" },
              { label: "Product ids", value: "productids" },
              { label: "None", value: null },
            ]}
            id="specificType"
            value={formik.values.specificType}
            onChange={formik.handleChange("specificType")}
          />
        </div>

        {formik.values.specificType === null ? null : (
          <>
            <div className="mb-5 flex flex-row items-center">
              <OutlineTextInput
                label={"Specific Ids"}
                id="specificIdState"
                value={formik.values.specificIdState}
                onChange={formik.handleChange}
              />

              <div
                className="bg-black p-3.5 rounded ml-2"
                type="reset"
                onClick={() => {
                  if (formik.values.specificIdState !== "") {
                    setSpecificIds([
                      ...specificIds,
                      formik.values.specificIdState,
                    ]);
                    formik.resetForm({
                      values: { ...formik.values, specificIdState: "" },
                    });
                  }
                }}
              >
                <MdAdd color={Colors.white} size={28} />
              </div>
            </div>

            {specificIds?.length === 0 ? null : (
              <div className="flex flex-wrap items-center mb-5">
                {specificIds?.map((item, index) => (
                  <div
                    key={index}
                    className="bg-lightGray rounded py-1 px-3 m-1 text-base tracking-wide flex items-center"
                  >
                    <TextComp text={item} />
                    <MdCancel size={18} color={Colors.black} className="ml-2" />
                  </div>
                ))}
              </div>
            )}
          </>
        )}

        <>
          <div className="mb-5 flex flex-row items-center">
            <OutlineTextInput
              label={"Customer Ids"}
              id="customerIdsState"
              value={formik.values.customerIdsState}
              onChange={formik.handleChange}
            />

            <div
              className="bg-black p-3.5 rounded ml-2"
              type="reset"
              onClick={() => {
                if (formik.values.customerIdsState !== "") {
                  setCustomerIds([
                    ...customerIds,
                    formik.values.customerIdsState,
                  ]);
                  formik.resetForm({
                    values: { ...formik.values, customerIdsState: "" },
                  });
                }
              }}
            >
              <MdAdd color={Colors.white} size={28} />
            </div>
          </div>

          {customerIds?.length === 0 ? null : (
            <div className="flex flex-wrap items-center mb-5">
              {customerIds?.map((item, index) => (
                <div
                  key={index}
                  className="bg-lightGray rounded py-1 px-3 m-1 text-base tracking-wide flex items-center"
                >
                  <TextComp text={item} />
                  <MdCancel size={18} color={Colors.black} className="ml-2" />
                </div>
              ))}
            </div>
          )}
        </>

        <div className="mb-5">
          <OutlineTextInput
            label={"Limit"}
            id="limit"
            value={formik.values.limit}
            onChange={formik.handleChange}
            error={formik.touched.limit && formik.errors.limit ? true : false}
            helperText={
              formik.touched.limit && formik.errors.limit
                ? formik.errors.limit
                : null
            }
          />
        </div>

        <div className="mb-5 flex flex-row items-center">
          <CustomCheckbox
            id="limitToOnePerCustomer"
            value={formik.values.limitToOnePerCustomer}
            onChange={formik.handleChange}
          />

          <TextComp text={"Limit to once per user"} customStyle={"text-sm"} />
        </div>

        <div className="mb-5 flex flex-row items-center">
          <CustomCheckbox
            id="valid"
            value={formik.values.valid}
            onChange={formik.handleChange}
          />

          <TextComp text={"Valid"} customStyle={"text-sm"} />
        </div>

        <div className="mb-5 flex flex-row items-center">
          <CustomCheckbox
            id="showInList"
            value={formik.values.showInList}
            onChange={formik.handleChange}
          />

          <TextComp text={"Show coupon in list"} customStyle={"text-sm"} />
        </div>

        <div className="mb-5 flex flex-row items-center w-full">
          <div className="mr-5 w-full">
            <OutlineTextInput
              label={"Start Date"}
              id="startDate"
              value={formik.values.startDate}
              onChange={formik.handleChange}
              error={
                formik.touched.startDate && formik.errors.startDate
                  ? true
                  : false
              }
              helperText={
                formik.touched.startDate && formik.errors.startDate
                  ? formik.errors.startDate
                  : null
              }
            />
          </div>

          <div className="ml-5 w-full">
            <OutlineTextInput
              label={"End Date"}
              id="endDate"
              value={formik.values.endDate}
              onChange={formik.handleChange}
              error={
                formik.touched.endDate && formik.errors.endDate ? true : false
              }
              helperText={
                formik.touched.endDate && formik.errors.endDate
                  ? formik.errors.endDate
                  : null
              }
            />
          </div>
        </div>
      </div>

      <button className="w-full" type="submit">
        <Button btnText={discountData ? "Edit Offer" : "Create Offer"} />
      </button>
    </form>
  );
}

export default OffersForm;
