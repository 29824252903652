import React, { useState } from "react";

import NavbarAndFooterComp from "../../../components/NavbarAndFooterComp";
import { CgChevronDownO, CgChevronUpO } from "react-icons/cg";
import TextComp from "../../../components/TextComp";
import { PageHeader } from "../../../components/PageHeader";

function Faqs() {
  const generalQueriesData = {
    title: "General Queries",
    QA: [
      {
        question: "HOW LONG WILL MY ORDER TAKE TO ARRIVE?",
        answer:
          "The order usually takes 7-10 working days to reach all the metros and tier I cities, however for some pin codes it might take a little more time. In case of delay please write to us at contact@geniease.com",
      },

      {
        question: "HOW WOULD I KNOW IF MY ORDER IS PLACED?",
        answer:
          "You will get a confirmation of the placed order on your registered email ID and phone number. Besides, we will further notify you once it is dispatched from our warehouse.",
      },

      {
        question: "YOU SHIP WORLWIDE?",
        answer:
          "Yes, We do!\n geniease Customs will be delivered to you anywhere in the world, from our Studio.",
      },

      {
        question: "HOW DO I DETERMINE THE CORRECT SIZE FOR ME?",
        answer:
          "While placing your order you will find an option for the SIZE CHART on the product page which includes detailed information on sizes and fit. You may click on the same and find your perfect size",
      },
    ],
  };

  const canellationData = {
    title: "CANCELLATION, RETURN & REFUND",
    QA: [
      {
        question: "HOW TO PLACE AN RETURN / EXCHANGE REQUEST?",
        answer:
          "- Visit the Place your Return / Exchange Request section on the website / app or click here to raise a return/exchange request for your order.\n - Submit the required details as prompted.\n - Follow the instructions and select the item(s) you would want to Return/Exchange.\n Please note that the Return/Exchange request needs to be raised within 7 days of the delivery date. Reverse pickup will be done in 2-3 working days by our courier partner. Once we receive the product, we would get in touch with you to confirm your request. In addition, all returned products must be unused or unwashed or undamaged and must be returned with the original packing and tags when we receive them. Items without tags will not be accepted.",
      },
      {
        question: "HOW CAN I CANCEL AN ORDER?",
        answer:
          "You can cancel you order within 6 hours of placing the order via Whatsapp. You can opt to cancel order by clicking the cancel order button in the Order confirmation message.",
      },

      {
        question: "HOW DO I RETURN / EXCHANGE A PRODUCT?",
        answer:
          "In case of returns/exchange, visit the returns section on the website to raise a return request for your order. You need to pack the product back in its original packing (with tags) and handover the packet to our courier person. As soon as we have it back in our warehouse, we will verify and issue a refund on the same account details used while making payment.\n Please note that handling fee of INR 100 is charged for returns.\n In addition, order placed during any sale (eg: BOGO) which includes free products are not eligible for returns / exchanges.",
      },
    ],
  };

  const paymentsData = {
    title: "PAYMENTS",
    QA: [
      {
        question: "IS CASH ON DELIVERY (COD) AVAILABLE?",
        answer:
          "Yes! COD is available for orders below INR 5000 on all serviceable pincodes.",
      },
      {
        question: "WHEN WILL I GET MY REFUND?",
        answer:
          "It takes 7-10 days for the product to arrive at our warehouse after the reverse pickup is done, after which it undergoes a quality check. Once it passes the QC, the refund for return or the exchange request will be initiated within 72 hours.\n Please note if any promotional/coupon code is redeemed during a purchase, the full amount will be refunded as store credit. In case of a non-promotional purchase, the full amount will be credited to the bank account.",
      },
      {
        question: "HOW DO I GET MY REFUND FOR CASH ON DELIVERY ORDERS?",
        answer:
          "Once the product(s) reaches us and passes the QC, refund in the form of store credit will be sent to the registered E-mail and WhatsApp number.",
      },
      {
        question:
          "MY PAYMENT WAS DEDUCTED FROM THE BANK ACCOUNT BUT THE ORDER WASN’T PLACED. WHAT SHOULD I DO?",
        answer:
          "In most cases, the payment gets automatically credited back to you account within 2-3 business days. In case it does not, mail us the details of your transaction at contact@geniease.com. We will verify and get back to you at the earliest.",
      },
    ],
  };

  return (
    <div>
      <NavbarAndFooterComp
        children={
          <div className="mb-20">
            {/* <div className="mx-5 mb-5">
              <BackButton navigate={navigate} />
            </div> */}
            <PageHeader text={"FAQ's"} />

            <div className="mx-5 md:mx-10 lg:mx-20">
              <HeaderText text={generalQueriesData.title} />
              {generalQueriesData.QA.map((item, index) => (
                <QAComp
                  key={index}
                  question={item.question}
                  answer={item.answer}
                />
              ))}
            </div>

            <div className="mx-5 md:mx-10 lg:mx-20">
              <HeaderText text={canellationData.title} />
              {canellationData.QA.map((item, index) => (
                <QAComp
                  key={index}
                  question={item.question}
                  answer={item.answer}
                />
              ))}
            </div>

            <div className="mx-5 md:mx-10 lg:mx-20">
              <HeaderText text={paymentsData.title} />
              {paymentsData.QA.map((item, index) => (
                <QAComp
                  key={index}
                  question={item.question}
                  answer={item.answer}
                />
              ))}
            </div>
          </div>
        }
      />
    </div>
  );
}

const HeaderText = ({ text }) => {
  return <TextComp customStyle="text-3xl tracking-wider my-10" text={text} />;
};

const QAComp = ({ question, answer }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="mb-2">
      <div className="flex flex-row">
        <div>
          {open === false ? (
            <CgChevronDownO
              size={25}
              className="mr-4 cursor-pointer"
              onClick={() => setOpen(true)}
            />
          ) : (
            <CgChevronUpO
              size={25}
              className="mr-4 cursor-pointer"
              onClick={() => setOpen(false)}
            />
          )}
        </div>
        <TextComp text={question} />
      </div>

      {open === true ? (
        <TextComp
          customStyle={"mt-1 ml-10 text-darkGray font-light"}
          text={answer}
        />
      ) : null}
    </div>
  );
};

export default Faqs;
