import { configureStore } from "@reduxjs/toolkit";
import UserDataSlice from "../UserData";
import LoadingDataSlice from "../LoadingData";
import BagDataSlice from "../BagData";

export const store = configureStore({
  reducer: {
    userData: UserDataSlice,
    LoadingData: LoadingDataSlice,
    bagData: BagDataSlice,
  },
});
