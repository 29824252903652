import { errorToastify } from "../../components/Toastify";
import { ErrorTexts } from "../../constants/ErrorTexts";
import { RoutesFetch } from "../RoutesFetch";

export const addItemInSavedList = ({
  setSavedItem,
  genieaseCustomerId,
  productId,
  fromBag,
  removeFromBag,
  setMoveProductDialog,
}) => {
  setSavedItem(true);

  RoutesFetch({
    method: "post",
    url: "/addSavedItem",
    data: {
      genieaseCustomerId,
      productId,
    },
  })
    .then((response) => {
      if (response.msg === "fail") {
        setSavedItem(false);
        errorToastify(ErrorTexts.sww);
      } else {
        if (fromBag === true) {
          removeFromBag();
          setMoveProductDialog(false);
        }
      }
    })
    .catch((err) => {
      setSavedItem(false);
      errorToastify(ErrorTexts.sww);
    });
};

export const removeItemInSavedList = ({
  setSavedItem,
  genieaseCustomerId,
  productId,
  fromWishlist,
  data,
  setData,
  index,
}) => {
  setSavedItem(false);

  RoutesFetch({
    method: "post",
    url: "/removeSavedItem",
    data: {
      genieaseCustomerId,
      productId,
    },
  })
    .then((response) => {
      if (response.msg === "fail") {
        setSavedItem(true);
        errorToastify(ErrorTexts.sww);
      } else {
        if (fromWishlist === true) {
          data.splice(index, 1);
          setData([...data]);
        }
      }
    })
    .catch((err) => {
      setSavedItem(true);
      errorToastify(ErrorTexts.sww);
    });
};
