import { createSlice } from "@reduxjs/toolkit";

export const UserDataSlice = createSlice({
  name: "userData",
  initialState: {},
  reducers: {
    setData: (state, action) => {
      const data = action.payload;
      state.data = data;
    },
  },
});

// this is for dispatch
export const { setData } = UserDataSlice.actions;

// this is for configureStore
export default UserDataSlice.reducer;
