import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { RoutesFetch } from "../../../../../config/RoutesFetch";
import { useState } from "react";
import { ErrorTexts } from "../../../../../constants/ErrorTexts";
import RouteWaiter from "../../../../../components/RouteWaiter";
import NavbarAndFooterComp from "../../../../../components/NavbarAndFooterComp";
import { Colors } from "../../../../../constants/Colors";
import { FaBox } from "react-icons/fa";
import TextComp from "../../../../../components/TextComp";
import moment from "moment";
import { bagCalculation } from "../../../../Bag/checkoutCommonFlow/CheckoutFunctions";

import { PageHeader } from "../../../../../components/PageHeader";

import CancelReturnOrder from "../CancelReturnOrder";
import DisplayProducts from "../DisplayProducts";

function OrderDetails() {
  const { orderId } = useParams();

  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState(null);

  const [errorText, setErrorText] = useState("");

  const [totalMRP, setTotalMRP] = useState(null);
  const [totalMRPDiscount, setTotalMRPDiscount] = useState(null);
  const [totalAmountToBePaid, setTotalAmountToBePaid] = useState(null);
  const [updateDetails, setUpdateDetails] = useState(0);

  useEffect(() => {
    setLoading(true);
    RoutesFetch({
      method: "get",
      url: "/getUserSingleOrders",
      params: {
        orderId,
      },
    })
      .then((res) => {
        if (res.msg === "fail") {
          setErrorText(ErrorTexts.sww);
        }
        {
          setOrderData(res?.orderData);
        }
      })
      .catch(() => {
        setErrorText(ErrorTexts.sww);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [updateDetails]);

  useEffect(() => {
    if (orderData !== null) {
      bagCalculation({
        setTotalMRP,
        setTotalMRPDiscount,
        setTotalAmountToBePaid,
        bagData: orderData?.checkoutData?.productDetails,
        discountAmount: orderData?.checkoutData?.discountAmount,
        setData: true,
      });
    }
  }, [orderData]);

  return (
    <NavbarAndFooterComp>
      <PageHeader text={"ORDER ITEMS"} />
      <RouteWaiter
        loading={loading}
        error={errorText}
        data={orderData}
        children={
          <div className="px-5 py-10 md:px-20">
            <div className="bg-white rounded shadow p-5">
              <div className="flex items-center">
                <div className="rounded-full bg-black p-3">
                  <FaBox color={Colors.white} size={20} />
                </div>
                <div>
                  <TextComp
                    text={orderData?.deliveryStatus?.toUpperCase()}
                    customStyle={"mx-4 text-sm font-medium tracking-widest"}
                  />
                  <TextComp
                    text={`Last updated on ${moment(
                      orderData?.updatedAt
                    ).format("DD-MM-YYYY hh:mm")}`}
                    customStyle={"mx-4 text-[9px] font-light text-darkGray"}
                  />
                </div>
              </div>

              <div>
                {orderData?.checkoutData?.productDetails.map(
                  (productItem, index) => (
                    <DisplayProducts
                      productItem={productItem}
                      orderData={orderData}
                    />
                  )
                )}
              </div>

              <div className="mt-10 bg-lightGray p-5 rounded">
                <FlexDiv title={"Total MRP"} value={totalMRP} />
                <FlexDiv
                  title={"Total Discount"}
                  value={totalMRPDiscount}
                  color="green"
                />
                {orderData?.checkoutData?.discountAmount === "0" ? null : (
                  <FlexDiv
                    title={"Coupon Discount"}
                    value={orderData?.checkoutData?.discountAmount}
                    color="green"
                  />
                )}
                <div className="border-[0.5px] mb-2 border-darkGray" />
                <FlexDiv title={"Total Amt Paid"} value={totalAmountToBePaid} />
              </div>

              {/* <div className="mt-5 flex justify-end cursor-pointer">
                <TextComp
                  text="Download Invoice"
                  customStyle={"text-rosePink text-xs tracking-wide underline"}
                />
              </div> */}

              <div className="mt-5 flex justify-end cursor-pointer">
                <CancelReturnOrder
                  orderData={orderData}
                  setUpdateDetails={setUpdateDetails}
                  updateDetails={updateDetails}
                />
              </div>
            </div>
          </div>
        }
      />
    </NavbarAndFooterComp>
  );
}

const FlexDiv = ({ title, value, color }) => {
  return (
    <div className="flex flex-row items-center justify-between text-sm tracking-wide font-medium mb-2">
      <TextComp text={title} />
      <TextComp
        text={value}
        customStyle={color === "green" ? "text-green" : null}
      />
    </div>
  );
};

export default OrderDetails;
