import { setData } from "../Redux/UserData";
import { RoutesFetch } from "../RoutesFetch";

export const VerifyTokenFunc = ({ setLoading, dispatch, cookies }) => {
  setLoading(true);

  RoutesFetch({
    method: "get",
    url: "/verifyStoreToken",
  })
    .then((response) => {
      if (response.msg !== "fail") {
        dispatch(setData(response.userData));
      }
    })
    .catch((err) => {
      if (!cookies?.userLoggedIn) {
        RoutesFetch({
          method: "post",
          url: "/createAnonymousUser",
        })
          .then((response) => {
            if (response.msg !== "fail") {
              dispatch(setData(response.userData));
            }
          })
          .catch(() => {});
      }
    })
    .finally(() => {
      setLoading(false);
    });
};
