import React, { useEffect, useState } from "react";

import Banner from "../../assets/banner/home.jpg";
import NavbarAndFooterComp from "../../components/NavbarAndFooterComp";
import ProductList from "../../components/ProductList";
import { sampleData } from "../../data/sampleData";
import ProductSkeleton from "../../components/ProductSkeleton";
import { RoutesFetch } from "../../config/RoutesFetch";
import RouteWaiter from "../../components/RouteWaiter";
import { ProductListFetch } from "../../config/ProductListFetch";
import { CollectionIds } from "../../config/CollectionIds";
import InfiniteScrollCompProductList from "../../components/InfiniteScrollComp/ProductList";

function Home() {
  const [productLoader, setProductLoader] = useState(true);
  const [productData, setProductData] = useState(null);

  // useEffect(() => {
  //   ProductListFetch({
  //     url: "/getProductsByCollection",
  //     params: { collectionId: CollectionIds.home },
  //     setLoading: setProductLoader,
  //     setData: setProductData,
  //   });
  // }, []);

  return (
    <NavbarAndFooterComp
      children={
        <div>
          <img src={Banner} className="w-full" />

          {/* <RouteWaiter
            loading={productLoader}
            error={""}
            errorChildren={null}
            data={productData}
            children={<ProductList data={productData} />}
            noDataChildren={null}
            loaderComp={<ProductSkeleton numberofSkeletonToShow={8} />}
          /> */}

          <InfiniteScrollCompProductList
            url="/getProductsByCollection"
            params={{ collectionId: CollectionIds.home }}
            responseDataName="productData"
            setLoading={setProductLoader}
            errorToastifyBoolean={true}
            // setErrorText={setErrorText}
            data={productData}
            setData={setProductData}
            noLoadMore={true}
            // valueOfUseEffectArray={[collectionId]}
            children={
              <RouteWaiter
                loading={productLoader}
                error={""}
                errorChildren={null}
                data={productData}
                children={<ProductList data={productData} />}
                noDataChildren={null}
                loaderComp={<ProductSkeleton numberofSkeletonToShow={8} />}
                showNoDataAndError={false}
              />
            }
          />
        </div>
      }
    />
  );
}

export default Home;
