import React, { useEffect } from "react";

import { Colors } from "../../../../../constants/Colors";
import TextComp from "../../../../../components/TextComp";

import {
  DiscountCalculation,
  RoundOffFloatIntoTwo,
} from "../../../../../config/PriceCalculation";

import { MdOutlineLocalOffer } from "react-icons/md";

function DisplayProducts({ productItem, orderData }) {
  return (
    <div className="flex items-center my-2.5">
      <img src={productItem?.image?.src} className="w-20" />
      <div>
        <TextComp
          text={productItem?.title}
          customStyle={"text-[16px] text-black"}
        />
        <TextComp
          text={`qty: ${productItem?.quantity}`}
          customStyle={"text-[14px] text-black"}
        />
        {productItem?.variants?.title === "Default Title" ? null : (
          <TextComp
            text={`size: ${productItem?.variants?.title}`}
            customStyle={"text-[14px] text-black"}
          />
        )}
        {productItem?.freeProduct === true ? (
          <div className="flex flex-row items-center rounded bg-green w-fit p-2 mt-2">
            <TextComp
              text={"Free"}
              customStyle="px-2 text-xs font-medium text-white"
            />
          </div>
        ) : (
          <div className="flex flex-row items-end">
            {productItem?.discountOnProduct ? (
              <TextComp
                text={`₹ ${RoundOffFloatIntoTwo(
                  productItem?.variants?.price * productItem?.quantity -
                    productItem?.discountOnProduct
                )}`}
                customStyle={"font-medium pr-2"}
              />
            ) : (
              <TextComp
                text={`₹ ${RoundOffFloatIntoTwo(
                  productItem?.variants?.price * productItem?.quantity
                )}`}
                customStyle={"font-medium pr-2"}
              />
            )}

            {productItem?.variants?.compare_at_price === "0" ||
            productItem?.variants?.compare_at_price === 0 ||
            !productItem?.variants?.compare_at_price ? null : (
              <>
                <TextComp
                  customStyle="pr-2 text-xs text-darkGray line-through pb-1"
                  text={`MRP ₹ ${RoundOffFloatIntoTwo(
                    productItem?.variants?.compare_at_price *
                      productItem?.quantity
                  )}`}
                />

                <TextComp
                  customStyle="pr-2 text-xs font-medium text-rosePink pb-1"
                  text={`(${DiscountCalculation(
                    productItem?.variants?.price * productItem?.quantity,
                    productItem?.variants?.compare_at_price *
                      productItem?.quantity
                  )} % OFF)`}
                />
              </>
            )}
          </div>
        )}

        {productItem?.discountOnProduct ? (
          <div className="flex flex-row items-center rounded bg-green w-fit p-2 mt-2">
            <MdOutlineLocalOffer color={Colors.white} />
            <TextComp
              customStyle="px-2 text-xs font-medium text-white"
              text={`${orderData?.checkoutData?.discount?.couponCode}`}
            />
            <TextComp
              customStyle="pr-2 text-xs font-medium text-white"
              text={`- (₹${productItem?.discountOnProduct})`}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default DisplayProducts;
