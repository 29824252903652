import React from "react";

import NavbarAndFooterComp from "../../../components/NavbarAndFooterComp";
import TextComp from "../../../components/TextComp";

import UserDetails from "../../../components/UserDetails";

function PersonalDetais() {
  return (
    <NavbarAndFooterComp
      children={
        <div className="py-10 md:py-20 px-5 md:px-10 lg:px-20 flex flex-col items-center">
          <TextComp
            text={"MY GENIEASE ACCOUNT"}
            customStyle={
              "text-xl md:text-3xl lg:text-4xl font-light tracking-widest pb-4"
            }
          />
          <TextComp
            text={"WE JUST NEED FEW MORE DETAILS"}
            customStyle={
              "text-xl md:text-3xl lg:text-4xl font-light tracking-widest pb-4"
            }
          />

          <UserDetails />

          <div className="mt-20 w-full">
            <TextComp
              text={"JOIN MY GENIEASE"}
              customStyle={
                "text-3xl md:text-3xl lg:text-4xl pb-5 md:pb-10 font-light tracking-widest text-center"
              }
            />

            <div className="lg:grid grid-cols-3 w-full">
              <div className="flex flex-col items-center p-10 col-span-1 border border-lightGray">
                <TextComp
                  text={"TRACK YOUR ORDERS"}
                  customStyle={"font-medium pb-2"}
                />
                <TextComp
                  text={"Follow your orders every step of the way."}
                  customStyle={"text-xs font-light"}
                />
              </div>

              <div className="flex flex-col items-center p-10 col-span-1 border border-lightGray">
                <TextComp
                  text={"STREAMLINE CHECKOUT"}
                  customStyle={"font-medium pb-2"}
                />
                <TextComp
                  text={
                    "Check out faster with saved addresses and payment methods."
                  }
                  customStyle={"text-xs font-light"}
                />
              </div>

              <div className="flex flex-col items-center p-10 col-span-1 border border-lightGray">
                <TextComp
                  text={"BOOK AN APPOINTMENT"}
                  customStyle={"font-medium pb-2"}
                />
                <TextComp
                  text={
                    "Plan a personalized visit at a store or virtually in the Digital Showroom."
                  }
                  customStyle={"text-xs font-light"}
                />
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default PersonalDetais;
