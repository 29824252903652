import React, { useEffect, useState } from "react";
import TextComp from "../TextComp";
import { TiHeartOutline, TiHeartFullOutline } from "react-icons/ti";
import { Colors } from "../../constants/Colors";
import { useSelector, useDispatch } from "react-redux";
import {
  addItemInSavedList,
  removeItemInSavedList,
} from "../../config/SavedItemsFunc";
import { useNavigate } from "react-router-dom";
import Button, { OutlineButton } from "../Button";
import { DiscountCalculation } from "../../config/PriceCalculation";
import CustomModal from "../Modal";
import { IoCloseOutline } from "react-icons/io5";
import {
  addItemToBag,
  addItemToBagSingleCheckout,
} from "../../config/BagItemsFunc";
import Loader from "../Loader";

import { infoToastify } from "../Toastify";
import { InfoTexts } from "../../constants/InfoTexts";
import SingleCheckoutDrawer from "./SingleCheckoutDrawer";

function ProductList({ data, setData, fromWishlist = false }) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.userData?.data);
  const bagData = useSelector((state) => state?.bagData?.data);

  const [loading, setLoading] = useState(false);

  const [showSingleCheckoutDrawer, setShowSingleCheckoutDrawer] =
    useState(false);

  return (
    <div className="md:p-10">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {data.map((item, index) => (
          <ProductListComp
            item={item}
            key={index}
            userData={userData}
            bagData={bagData}
            setLoading={setLoading}
            dispatch={dispatch}
            setShowSingleCheckoutDrawer={setShowSingleCheckoutDrawer}
            fromWishlist={fromWishlist}
            data={data}
            setData={setData}
            index={index}
          />
        ))}
      </div>

      <SingleCheckoutDrawer
        showSingleCheckoutDrawer={showSingleCheckoutDrawer}
        setShowSingleCheckoutDrawer={setShowSingleCheckoutDrawer}
      />

      <Loader loading={loading} />
    </div>
  );
}

const ProductListComp = ({
  item,
  userData,
  dispatch,
  bagData,
  setLoading,
  setShowSingleCheckoutDrawer,
  fromWishlist,
  data,
  setData,
  index,
}) => {
  const [savedItem, setSavedItem] = useState(
    item?.inWishlist === true || fromWishlist === true ? true : false
  );
  const [showVariants, setShowVariants] = useState(false);
  const [buttonClickedOn, setButtonClickedOn] = useState(null);
  const [outOfStock, setOutOfStock] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    for (let i = 0; i < item?.node?.variants?.edges?.length; i++) {
      if (item?.node?.variants?.edges[i]?.node?.inventoryQuantity > 0) {
        break;
      } else {
        if (item?.node?.variants?.edges?.length - 1 === i) {
          setOutOfStock(true);
          break;
        }
      }
    }
  }, []);

  return (
    <div
      className="cursor-pointer relative col-span-1 p-2"
      onClick={() => {
        navigate(
          `/product/${item?.node?.id.replace("gid://shopify/Product/", "")}`
        );
      }}
    >
      <div className="flex items-center justify-center bg-lightGray rounded-t-xl">
        <img
          src={item?.node?.images?.edges[0]?.node?.src}
          className="object-contain bg-lightGray rounded-t-xl aspect-3/4"
        />
      </div>

      <div className="">
        <div className="mt-4 w-full h-[100px]">
          <TextComp
            text={item?.node?.title.toUpperCase()}
            customStyle={
              "text-[14px] font-light tracking-widest text-black pb-0.5"
            }
          />

          {item?.node?.tags?.join()?.match(/variant - (.+?)(?:,|$)/) ? (
            <TextComp
              customStyle={
                "text-[12px] font-light tracking-widest text-darkGray pb-2"
              }
              text={item?.node?.tags
                ?.join()
                ?.match(/variant - (.+?)(?:,|$)/)[1]
                .toUpperCase()}
            />
          ) : (
            <TextComp
              customStyle={
                "text-[12px] font-light tracking-widest text-darkGray pb-2 "
              }
              text={"ORIGINALS"}
            />
          )}

          <div className="flex items-center justify-between">
            <div className="flex flex-row items-end">
              <TextComp
                text={`₹ ${Math.floor(
                  item?.node?.variants?.edges[0]?.node?.price
                )}`}
                customStyle={"text-lg font-medium pr-2"}
              />

              {item?.node?.variants?.edges[0]?.node?.compareAtPrice === "0" ||
              item?.node?.variants?.edges[0]?.node?.compareAtPrice === 0 ||
              !item?.node?.variants?.edges[0]?.node?.compareAtPrice ? null : (
                <>
                  <TextComp
                    customStyle="pr-2 text-xs text-darkGray line-through pb-1"
                    text={`₹ ${Math.floor(
                      item?.node?.variants?.edges[0]?.node?.compareAtPrice
                    )}`}
                  />

                  <TextComp
                    customStyle="pr-2 text-xs font-medium text-rosePink pb-1"
                    text={`(${DiscountCalculation(
                      item?.node?.variants?.edges[0]?.node?.price,
                      item?.node?.variants?.edges[0]?.node?.compareAtPrice
                    )} % OFF)`}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        <div
          className="mt-2"
          onClick={(e) => {
            if (item?.node?.variants?.edges?.length > 1) {
              e.stopPropagation();
              setButtonClickedOn("bag");
              setShowVariants(true);
            } else {
              e.stopPropagation();
              addItemToBag({
                productId: item?.node?.id.replace("gid://shopify/Product/", ""),
                variantId: item?.node?.variants?.edges[0]?.node?.id?.replace(
                  "gid://shopify/ProductVariant/",
                  ""
                ),
                quantity: 1,
                setLoading,
                dispatch,
                bagData,
              });
            }
          }}
        >
          <OutlineButton
            btnText={"ADD TO BAG"}
            customStyle={"py-2 rounded-none"}
          />
        </div>
        <div
          className=""
          onClick={(e) => {
            if (item?.node?.variants?.edges?.length > 1) {
              e.stopPropagation();
              setButtonClickedOn("buy");
              setShowVariants(true);
            } else {
              e.stopPropagation();
              addItemToBagSingleCheckout({
                productId: item?.node?.id.replace("gid://shopify/Product/", ""),
                variantId: item?.node?.variants?.edges[0]?.node?.id?.replace(
                  "gid://shopify/ProductVariant/",
                  ""
                ),
                quantity: 1,
                setLoading,
                setShowSingleCheckoutDrawer,
              });
            }
          }}
        >
          <Button
            btnText={"BUY NOW"}
            customStyle={"py-2 rounded-t-none rounded-b-xl"}
          />
        </div>

        {outOfStock === true ? (
          <div className="absolute top-1/3 w-full">
            <div className="bg-white opacity-80">
              <TextComp
                text={"SOLD OUT"}
                customStyle={"text-center p-2 tracking-widest"}
              />
            </div>
          </div>
        ) : null}

        <div className="absolute top-0 right-0 m-2">
          {savedItem === false ? (
            <div
              className="bg-lightGray rounded-full p-1.5 m-2"
              onClick={(e) => {
                e.stopPropagation();

                addItemInSavedList({
                  setSavedItem,
                  genieaseCustomerId: userData?.genieaseCustomerId,
                  productId: item?.node?.id.replace(
                    "gid://shopify/Product/",
                    ""
                  ),
                });
              }}
            >
              <TiHeartOutline color={Colors.black} size={20} />
            </div>
          ) : (
            <div
              className="bg-lightGray rounded-full p-1.5 m-2"
              onClick={(e) => {
                e.stopPropagation();

                removeItemInSavedList({
                  setSavedItem,
                  genieaseCustomerId: userData?.genieaseCustomerId,
                  productId: item?.node?.id.replace(
                    "gid://shopify/Product/",
                    ""
                  ),
                  fromWishlist,
                  data,
                  setData,
                  index,
                });
              }}
            >
              <TiHeartFullOutline color={Colors.rosePink} size={20} />
            </div>
          )}
        </div>
        {showVariants === false || buttonClickedOn === null ? null : (
          <div className="absolute bottom-0 w-full mb-2 h-1/2 flex flex-row items-center justify-center">
            <div className="h-full w-full mr-4 bg-lightGray rounded-b-lg opacity-95 overflow-y-scroll scrollbar-hide">
              <div className="p-3 font-medium tracking-wide flex justify-between items-center">
                <TextComp text={"Select Size"} />

                <IoCloseOutline
                  color={Colors.black}
                  size={25}
                  className="cursor-pointer hover:p-1 duration-500"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowVariants(false);
                  }}
                />
              </div>

              {item?.node?.variants?.edges?.map((variantItem, index) =>
                variantItem?.node?.inventoryQuantity > 0 ? (
                  <div
                    key={index}
                    className="p-3 border-t w-full text-sm border-darkGray"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowVariants(false);

                      buttonClickedOn === "buy"
                        ? addItemToBagSingleCheckout({
                            productId: item?.node?.id.replace(
                              "gid://shopify/Product/",
                              ""
                            ),
                            variantId: variantItem?.node?.id?.replace(
                              "gid://shopify/ProductVariant/",
                              ""
                            ),
                            quantity: 1,
                            setLoading,
                            setShowSingleCheckoutDrawer,
                          })
                        : addItemToBag({
                            productId: item?.node?.id.replace(
                              "gid://shopify/Product/",
                              ""
                            ),
                            variantId: variantItem?.node?.id?.replace(
                              "gid://shopify/ProductVariant/",
                              ""
                            ),
                            quantity: 1,
                            setLoading,
                            dispatch,
                            bagData,
                          });
                    }}
                  >
                    <TextComp
                      text={variantItem?.node?.title}
                      customStyle={"text-center"}
                    />
                  </div>
                ) : (
                  <div
                    key={index}
                    className="p-3 border-t w-full text-sm border-darkGray"
                    onClick={(e) => {
                      e.stopPropagation();

                      infoToastify(InfoTexts.outOfStock);
                    }}
                  >
                    <TextComp
                      text={variantItem?.node?.title}
                      customStyle={"text-center bg-lightGray opacity-30"}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductList;
