import React from "react";

import TextComp from "../../components/TextComp";

function Button({ btnText, onPress, customStyle, customBtnStyle }) {
  return (
    <div
      className={`border border-black bg-black text-white py-5 px-10 w-full text-center rounded cursor-pointer ${customStyle}`}
      onClick={onPress}
    >
      <TextComp
        text={btnText}
        customStyle={`text-sm tracking-widest ${customBtnStyle}`}
      />
    </div>
  );
}

export function OutlineButton({ btnText, onPress, customStyle }) {
  return (
    <div
      className={`border border-black bg-white py-5 px-10 w-full text-center rounded cursor-pointer ${customStyle}`}
      onClick={onPress}
    >
      <TextComp text={btnText} customStyle={"text-sm tracking-widest"} />
    </div>
  );
}

export function ButtonWithIcon({
  btnText,
  onPress,
  customStyle,
  customBtnStyle,
  Icon,
}) {
  return (
    <div
      className={`border border-black bg-black text-white py-5 px-10 w-full text-center rounded cursor-pointer flex items-center justify-center ${customStyle}`}
      onClick={onPress}
    >
      <TextComp
        text={btnText}
        customStyle={`text-sm tracking-widest ${customBtnStyle}`}
      />
      <Icon size={20} className={"ml-2"} />
    </div>
  );
}
export default Button;
