import React, { useEffect, useState } from "react";

import InfiniteScroll from "react-infinite-scroller";

import { RoutesFetch } from "../../config/RoutesFetch";
import { ErrorTexts } from "../../constants/ErrorTexts";
import { InfiniteScrollLoader } from "../Loader";
import { errorToastify } from "../Toastify";

function InfiniteScrollComp({
  url,
  responseDataName,
  setLoading,
  setErrorText,
  errorToastifyBoolean = false,
  data,
  setData,
  params,
  children,
  valueOfUseEffectArray = [],
  setResponseFromApi = null,
}) {
  const [pageNumber, setPageNumber] = useState(0);
  const [isMoreLoading, setIsMoreLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchData(0);
  }, [...valueOfUseEffectArray]);

  const fetchData = (pageNo) => {
    RoutesFetch({
      method: "get",
      url,
      params: {
        pageNo,
        ...params,
      },
    })
      .then((response) => {
        if (response.msg === "fail") {
          errorToastifyBoolean === true
            ? errorToastify(ErrorTexts.sww)
            : setErrorText(ErrorTexts.sww);
        } else {
          const resDataArray = response[responseDataName];

          if (resDataArray.length === 0) {
            setIsMoreLoading(false);
          }

          if (pageNo === 0 && resDataArray.length !== 0) {
            setIsMoreLoading(true);
          }

          if (errorToastifyBoolean === false) {
            setErrorText("");
          }

          pageNo === 0
            ? setData(resDataArray)
            : setData([...data, ...resDataArray]);

          if (setResponseFromApi !== null) {
            setResponseFromApi(response);
          }

          setPageNumber(pageNo);
        }
      })
      .catch((err) => {
        errorToastifyBoolean === true
          ? errorToastify(ErrorTexts.sww)
          : setErrorText(ErrorTexts.sww);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={() => fetchData(pageNumber + 1)}
      hasMore={isMoreLoading}
      loader={
        <div className="flex flex-col items-center">
          <InfiniteScrollLoader />
        </div>
      }
    >
      {children}
    </InfiniteScroll>
  );
}

export default InfiniteScrollComp;
