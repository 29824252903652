import React, { useState } from "react";
import { Colors } from "../../../../../constants/Colors";
import { IoCloseOutline } from "react-icons/io5";
import {
  MobileTextInput,
  OutlineTextInput,
} from "../../../../../components/TextInput";
import TextComp from "../../../../../components/TextComp";
import SelectInput from "../../../../../components/TextInput/SelectInput";
import Button from "../../../../../components/Button";
import CustomCheckbox from "../../../../../components/Checkbox";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  errorToastify,
  infoToastify,
  successToastify,
} from "../../../../../components/Toastify";
import { ErrorTexts } from "../../../../../constants/ErrorTexts";
import { SuccessTexts } from "../../../../../constants/SuccessTexts";
import { RoutesFetch } from "../../../../../config/RoutesFetch";
import Loader from "../../../../../components/Loader";

function AddressForm({ setOpen, setIsFocused, isFocused, edit, data }) {
  const [loading, setLoading] = useState(false);

  const [city, setCity] = useState(edit === true ? data.city : "");
  const [state, setState] = useState(edit === true ? data.province : "");

  const validatePincode = ({ pincode }) => {
    setLoading(true);

    RoutesFetch({
      method: "post",
      url: "/validatePincode",
      data: {
        pincode,
      },
    })
      .then((res) => {
        if (res.msg === "fail") {
          errorToastify(ErrorTexts.sww);
          setCity("");
          setState("");
        } else {
          if (res.pincodeData === null) {
            errorToastify(ErrorTexts.enterValidPincode);
            setCity("");
            setState("");
          } else {
            setCity(
              res.pincodeData.district.toUpperCase()[0] +
                res.pincodeData.district.substring(1).toLowerCase()
            );
            setState(
              res.pincodeData.state.toUpperCase()[0] +
                res.pincodeData.state.substring(1).toLowerCase()
            );
          }
        }
      })
      .catch(() => {
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues:
      edit === true
        ? {
            firstName: data.first_name,
            lastName: data.last_name,
            address1: data.address1,
            address2: data.address2,
            // city: data.city,
            // state: data.province,
            postalCode: data.zip,
            mobileNumber: data.phone,
            defaultAddress: data.default,
          }
        : {
            firstName: "",
            lastName: "",
            address1: "",
            address2: "",
            // city: "",
            // state: "",
            postalCode: "",
            mobileNumber: "",
            defaultAddress: false,
          },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Please enter a valid name"),
      lastName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Please enter a valid name"),
      address1: Yup.string().required("Please enter your address"),
      // city: Yup.string().required("Please enter your city"),
      // state: Yup.string().required("Please enter your sate"),
      postalCode: Yup.string()
        .required("Please enter your postal code")
        .matches(`^[0-9]+$`, "Please enter a valid postal code")
        .min(6, "Please enter a valid postal code")
        .max(6, "Please enter a valid postal code"),
      mobileNumber: Yup.string()
        .required("Please enter a valid mobile number")
        .matches(`^[0-9]+$`, "Please enter a valid mobile number")
        .min(10, "Please enter a valid mobile number")
        .max(10, "Please enter a valid mobile number"),
    }),
    onSubmit: (values) => {
      setLoading(true);

      RoutesFetch({
        method: "post",
        url: edit === true ? "/editUserAddress" : "/addUserAddress",
        data:
          edit === true
            ? {
                addressId: data._id,
                firstName: values.firstName,
                lastName: values.lastName,
                address1: values.address1,
                address2: values.address2,
                city: city,
                state: state,
                postalCode: values.postalCode,
                mobileNumber: values.mobileNumber,
                defaultAddress: values.defaultAddress,
              }
            : {
                firstName: values.firstName,
                lastName: values.lastName,
                address1: values.address1,
                address2: values.address2,
                city: city,
                state: state,
                postalCode: values.postalCode,
                mobileNumber: values.mobileNumber,
                defaultAddress: values.defaultAddress,
              },
      })
        .then((response) => {
          if (response.msg === "fail") {
            errorToastify(ErrorTexts.sww);
          } else {
            setOpen(false);
            successToastify(
              edit === true
                ? SuccessTexts.addressEdited
                : SuccessTexts.addressAdded
            );
            setIsFocused(!isFocused);
          }
        })
        .catch((err) => {
          errorToastify(ErrorTexts.sww);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <div className="my-24 flex items-center justify-center">
      <div className="bg-white mx-5 md:mx-0 w-full md:w-2/3 lg:w-1/2 p-2">
        <div className="flex flex-col items-end m-2 my-4 md:m-4">
          <IoCloseOutline
            color={Colors.black}
            size={40}
            className="cursor-pointer hover:p-1 duration-500"
            onClick={() => setOpen(false)}
          />
        </div>

        <form onSubmit={formik.handleSubmit} className="m-2 md:m-10">
          <TextComp
            text={"NEW ADDRESS"}
            customStyle={
              "text-4xl tracking-widest font-light text-center border-b pb-10"
            }
          />

          <div className="grid grid-cols-2 mt-10">
            <div className="col-span-1 mr-2">
              <OutlineTextInput
                label={"First Name"}
                value={formik.values.firstName}
                id="firstName"
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && formik.errors.firstName
                    ? true
                    : false
                }
                helperText={
                  formik.touched.firstName && formik.errors.firstName
                    ? formik.errors.firstName
                    : null
                }
              />
            </div>

            <div className="col-span-1 ml-2">
              <OutlineTextInput
                label={"Last Name"}
                value={formik.values.lastName}
                id="lastName"
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && formik.errors.lastName
                    ? true
                    : false
                }
                helperText={
                  formik.touched.lastName && formik.errors.lastName
                    ? formik.errors.lastName
                    : null
                }
              />
            </div>
          </div>

          <div className="mt-10">
            <OutlineTextInput
              label={"Address Line 1"}
              value={formik.values.address1}
              id="address1"
              onChange={formik.handleChange}
              error={
                formik.touched.address1 && formik.errors.address1 ? true : false
              }
              helperText={
                formik.touched.address1 && formik.errors.address1
                  ? formik.errors.address1
                  : null
              }
            />
          </div>

          <div className="mt-10">
            <OutlineTextInput
              label={"Address Line 2"}
              value={formik.values.address2}
              id="address2"
              onChange={formik.handleChange}
            />
          </div>

          <div className="mt-10 grid grid-cols-3">
            <div className="col-span-1 mr-2">
              <OutlineTextInput
                label={"City"}
                id="city"
                value={city}
                disabled={true}
                // onChange={formik.handleChange}
                // error={formik.touched.city && formik.errors.city ? true : false}
                // helperText={
                //   formik.touched.city && formik.errors.city
                //     ? formik.errors.city
                //     : null
                // }
              />
            </div>

            <div className="col-span-1 mx-2">
              {/* <SelectInput
                label={"State"}
                values={["Mr.", "Mrs.", "Ms.", "I'd rather not say"]}
                id="state"
                onChange={formik.handleChange}
                error={
                  formik.touched.state && formik.errors.state ? true : false
                }
                helperText={
                  formik.touched.state && formik.errors.state
                    ? formik.errors.state
                    : null
                }
              /> */}
              <OutlineTextInput
                label={"State"}
                id="state"
                value={state}
                disabled={true}
                // onChange={formik.handleChange}
                // error={
                //   formik.touched.state && formik.errors.state ? true : false
                // }
                // helperText={
                //   formik.touched.state && formik.errors.state
                //     ? formik.errors.state
                //     : null
                // }
              />
            </div>

            <div className="col-span-1 ml-2">
              <OutlineTextInput
                label={"Postal Code"}
                id="postalCode"
                value={formik.values.postalCode}
                onChange={formik.handleChange}
                error={
                  formik.touched.postalCode && formik.errors.postalCode
                    ? true
                    : false
                }
                helperText={
                  formik.touched.postalCode && formik.errors.postalCode
                    ? formik.errors.postalCode
                    : null
                }
                inputProps={{ maxLength: 6 }}
                onBlur={
                  formik.values.postalCode.length === 6
                    ? () =>
                        validatePincode({ pincode: formik.values.postalCode })
                    : null
                }
              />
            </div>
          </div>

          <div className="mt-10">
            <MobileTextInput
              label={"Contact Number"}
              value={formik.values.mobileNumber}
              id="mobileNumber"
              onChange={formik.handleChange}
              error={
                formik.touched.mobileNumber && formik.errors.mobileNumber
                  ? true
                  : false
              }
              helperText={
                formik.touched.mobileNumber && formik.errors.mobileNumber
                  ? formik.errors.mobileNumber
                  : null
              }
            />
          </div>

          <div className="mt-10 flex flex-row items-center">
            <CustomCheckbox
              id="defaultAddress"
              value={formik.values.defaultAddress}
              onChange={formik.handleChange}
            />
            <TextComp
              text={"Make this my primary shipping address."}
              customStyle={"text-sm"}
            />
          </div>

          <button className="mt-10 w-full" type="submit">
            <Button btnText={"SAVE"} />
          </button>
        </form>
      </div>

      <Loader loading={loading} />
    </div>
  );
}

export default AddressForm;
