import React from "react";

import logo from "../../assets/logo/full_logo_white.png";
import TextComp from "../TextComp";

import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
} from "react-icons/ai";
import { Links } from "../../constants/Links";
import { useNavigate } from "react-router-dom";
import { TbShoppingBagSearch } from "react-icons/tb";
import { Colors } from "../../constants/Colors";
import { FiUser } from "react-icons/fi";
import { RxLoop } from "react-icons/rx";

function Footer() {
  const navigate = useNavigate();

  const FooterHeader = ({ text }) => {
    return (
      <TextComp
        text={text}
        customStyle={"text-darkGray text-xs font-semibold pb-6"}
      />
    );
  };

  const FooterList = ({ text, link }) => {
    return (
      <div onClick={() => navigate(link)}>
        <TextComp
          text={text}
          customStyle={
            "text-white text-xs font-light border-b w-fit cursor-pointer pb-0.2 mb-6 tracking-wider"
          }
        />
      </div>
    );
  };

  return (
    <div className="w-full">
      {/* <div className="w-full pt-10 pb-10 px-20 bg-lightGray grid grid-cols-3">
        <div
          className="col-span-1 flex flex-col justify-center items-center cursor-pointer"
          onClick={() => navigate("/myaccount")}
        >
          <FiUser size={60} color={Colors.darkGray} />
          <TextComp
            text={"MY ACCOUNT"}
            customStyle={"text-lg font-medium tracking-wider mt-2"}
          />
        </div>

        <div className="col-span-1 flex flex-col justify-center items-center cursor-pointer">
          <RxLoop size={60} color={Colors.darkGray} />
          <TextComp
            text={"RETURN & EXCHANGE"}
            customStyle={"text-lg font-medium tracking-wider mt-2"}
          />
        </div>

        <div className="col-span-1 flex flex-col justify-center items-center cursor-pointer">
          <TbShoppingBagSearch size={60} color={Colors.darkGray} />
          <TextComp
            text={"TRACK ORDER"}
            customStyle={"text-lg font-medium tracking-wider mt-2"}
          />
        </div>
      </div> */}

      <div className="w-full pt-10 pb-10 px-5 md:px-20 bg-black lg:grid grid-cols-2">
        <div className="py-6 flex flex-row justify-between col-span-1 pr-20">
          <div className="pr-10">
            <FooterHeader text={"MAY WE HELP YOU?"} />
            <FooterList text={"Customisation"} link={"/customisation"} />
            <FooterList text={"Contact Us"} link={"/contact-us"} />
            <FooterList text={"My Orders"} link={"/myorders"} />
            <FooterList text={"FAQs"} link={"/faqs"} />
          </div>

          <div className="pl-10">
            <FooterHeader text={"THE COMPANY"} />
            <FooterList text={"About geniease"} link={"/aboutus"} />
            <FooterList text={"Terms of Use"} link={"/terms"} />
            <FooterList text={"Privacy Policy"} link={"/policies"} />
          </div>
        </div>

        <div className="mt-10 lg:mt-0 col-span-1">
          <div className="flex flex-col justify-end items-end">
            <div className="pl-5 pr-20 py-2 rounded-lg bg-white">
              <TextComp
                text={"COUNTRY / REGION"}
                customStyle={"text-darkGray text-xs pb-2"}
              />
              <TextComp
                text={"INDIA"}
                customStyle={"text-black text-sm underline font-semibold"}
              />
            </div>
          </div>

          <div className="flex flex-col justify-end items-end mt-10">
            <FooterHeader text={"GET IN TOUCH"} />
            <div className="flex flex-row items-end">
              <a href={Links.facebookLink} target="_blank">
                <AiFillFacebook size={30} className="mr-5 text-white" />
              </a>
              <a href={Links.instagramLink} target="_blank">
                <AiFillInstagram size={30} className="mr-5 text-white" />
              </a>
              <a href={Links.linkedInLink} target="_blank">
                <AiFillLinkedin size={30} className="text-white" />
              </a>
              {/* <AiFillYoutube size={30} className="mr-2" /> */}
            </div>
          </div>

          <div className="flex flex-col justify-end items-end pb-6 mt-10">
            <img src={logo} className="w-80 cursor-pointer" />
            <TextComp
              text={"© 2024 geniease - All rights reserved."}
              customStyle={"text-white text-xs -mt-8"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
