import React from "react";

import SadWallet from "../../../assets/noDataImg/sad-wallet.webp";
import TextComp from "../../TextComp";
import Button from "../../Button";
import { useNavigate } from "react-router-dom";

function SWWComp({
  title = "Oops! We're currently refreshing our style—stay tuned for the chic comeback!",
  text,
}) {
  const naviagte = useNavigate();

  return (
    <div className="w-full h-screen flex flex-row justify-center items-center">
      <div className="w-2/3 flex flex-col justify-center items-center -mt-28 md:-mt-40">
        <img src={SadWallet} className="h-60 mb-5" />
        <TextComp
          text={title}
          customStyle={"text-xs tracking-wider text-center"}
        />
        <Button
          onPress={() => {
            naviagte("/customisation");
          }}
          btnText={"Customize Now"}
          customStyle={"w-64 my-5"}
        />
      </div>
    </div>
  );
}

export default SWWComp;
