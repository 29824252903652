const { environment } = require("../EnvConfig");

let CollectionIds = {
  home: "313171247272",
  newin: "313171247272",
  bestseller: "313171247272",
  customisation: "313171247272",
  tshirt: "313171247272",
  men: "313171247272",
  women: "313171247272",
  fragrances: "313171247272",
  gift: "313171247272",

  sneakers: "313171247272",
  handbags: "313171247272",
  accessories: "313171247272",
};

if (environment === "dev") {
  CollectionIds = {
    home: "313171247272",
    newin: "313171247272",
    bestseller: "313171247272",
    customisation: "313171247272",
    tshirt: "313171247272",
    men: "313171247272",
    women: "313171247272",
    fragrances: "313171247272",
    gift: "313171247272",

    sneakers: "313171247272",
    handbags: "313171247272",
    accessories: "313171247272",
  };
}

export { CollectionIds };
