import React from "react";
import Checkbox from "@mui/material/Checkbox";
import { Colors } from "../../constants/Colors";

function CustomCheckbox({ onChange, id, value }) {
  return (
    <div>
      <Checkbox
        sx={{
          color: Colors.black,
          "&.Mui-checked": {
            color: Colors.black,
          },
        }}
        id={id}
        checked={value}
        onChange={onChange}
      />
    </div>
  );
}

export default CustomCheckbox;
