export const DeliveryStatuses = [
  {
    label: "ORDERED",
    value: "ordered",
  },
  {
    label: "PACKED",
    value: "packed",
  },
  {
    label: "IN TRANSIT",
    value: "intransit",
  },
  {
    label: "DELIVERED",
    value: "delivered",
  },
  // {
  //   label: "RETURNED",
  //   value: "returned",
  // },
  // {
  //   label: "CANCELLED",
  //   value: "cancelled",
  // },
];

export const ReturnStatuses = [
  {
    label: "RETURNED",
    value: "return",
  },
  {
    label: "EXCHANGED",
    value: "exchange",
  },
  {
    label: "CANCELLED",
    value: "cancel",
  },
];

export const CustomisationStatuses = [
  {
    label: "PENDING",
    value: "pending",
  },
  {
    label: "IN DISCUSSION",
    value: "indiscussion",
  },
  {
    label: "In PROCESS",
    value: "inprocess",
  },
  {
    label: "IN TRANSIT",
    value: "intransit",
  },
  {
    label: "DELIVERED",
    value: "delivered",
  },
  {
    label: "CANCELLED",
    value: "cancel",
  },
];
