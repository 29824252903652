import React from "react";

import TextField from "@mui/material/TextField";
import { Colors } from "../../constants/Colors";
import TextComp from "../TextComp";

function OutlineTextInput({ label, ...props }) {
  return (
    <div className="w-full">
      <TextField
        id="outlined"
        label={label}
        fullWidth={true}
        sx={{
          "& .MuiInputLabel-outlined": {
            fontSize: 16,
            "&.Mui-error": {
              color: Colors.red,
            },
            "&.Mui-focused": {
              "&.Mui-error": {
                color: Colors.red,
              },
              color: Colors.black,
            },
          },
          // Root class for the input field
          "& .MuiOutlinedInput-root": {
            // Class for the border around the input field
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: Colors.black,
              borderWidth: 1,
            },

            "&.Mui-error": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: Colors.red,
                borderWidth: 1,
              },
            },

            "&.Mui-focused": {
              "&.Mui-error": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: Colors.red,
                  borderWidth: 1,
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: Colors.black,
                borderWidth: 1,
              },
            },
          },
        }}
        {...props}
      />
    </div>
  );
}

const MobileTextInput = ({ label, ...props }) => {
  return (
    <div className="flex flex-row items-start">
      <TextComp
        text={"+91"}
        customStyle={
          "bg-lightGray px-4 py-[17px] mr-1 border rounded text-sm font-medium tracking-wider"
        }
      />
      <OutlineTextInput label={label} {...props} />
    </div>
  );
};

function StandardTextInput({ label, ...props }) {
  return (
    <TextField
      id="standard"
      variant="standard"
      label={label}
      fullWidth={true}
      sx={{
        "& label": {
          fontSize: 12,
          "&.Mui-focused": {
            color: Colors.black,
            fontSize: 14,
          },
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: Colors.black,
        },
      }}
      {...props}
    />
  );
}

export { OutlineTextInput, MobileTextInput, StandardTextInput };
