import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { BsBagCheckFill } from "react-icons/bs";

import Button, { OutlineButton } from "../../../components/Button";
import { Colors } from "../../../constants/Colors";
import TextComp from "../../../components/TextComp";
import { useNavigate } from "react-router-dom";

function OrderSuccessComp({
  setOrderSuccess,
  navigationRoute,
  title,
  details,
  btnText,
}) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="bg-white p-2">
        <div className="flex flex-col items-end m-4">
          <IoCloseOutline
            color={Colors.black}
            size={40}
            className="cursor-pointer hover:p-1 duration-500"
            onClick={() => setOrderSuccess(false)}
          />
        </div>

        <div className="flex flex-col items-center m-10">
          <BsBagCheckFill size={100} color={Colors.black} />
          <TextComp
            text={title}
            customStyle={"mt-2 text-lg tracking-widest text-black"}
          />
          <TextComp
            text={details}
            customStyle={"text-xs tracking-wide text-darkGray text-center"}
          />
          <div className="mt-5 w-full flex items-center">
            {/* <div className="mx-2">
              <OutlineButton
                btnText={"GO BACK"}
                onPress={() => {
                  setOrderSuccess(false);
                }}
              />
            </div> */}

            {/* <div className="mx-2"> */}
            <Button
              btnText={btnText}
              onPress={() => {
                navigate(navigationRoute);
              }}
            />
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderSuccessComp;
