import React from "react";
import Skeleton from "@mui/material/Skeleton";

function ProductSkeleton({ numberofSkeletonToShow }) {
  const array = new Array(numberofSkeletonToShow);
  array.fill(undefined);

  return (
    <div className="md:p-10">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {array.map((item, index) => (
          <div key={index} className="cursor-pointer relative col-span-1 p-2">
            <Skeleton
              variant="rounded min-h-[400px]"
              className="rounded-xl bg-lightGray"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductSkeleton;
