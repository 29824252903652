import { createSlice } from "@reduxjs/toolkit";

export const BagDataSlice = createSlice({
  name: "bagData",
  initialState: {},
  reducers: {
    setBagData: (state, action) => {
      const data = action.payload;
      state.data = data;
    },
  },
});

// this is for dispatch
export const { setBagData } = BagDataSlice.actions;

// this is for configureStore
export default BagDataSlice.reducer;
