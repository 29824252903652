import React, { useCallback, useEffect, useState } from "react";
import { StandardTextInput } from "../../components/TextInput";
import TextComp from "../../components/TextComp";
import { LuSearch } from "react-icons/lu";
import { searchCategories } from "../../constants/searchCategories";
import { RoutesFetch } from "../../config/RoutesFetch";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";

function Search({ setSearchMenu, setShowSearchDrawer }) {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [filteredItems, setFilteredItems] = useState(searchCategories);

  const [productSearchResult, setProductSearchResult] = useState([]);

  useEffect(() => {
    if (searchText !== "") {
      searchDebounceFunction();
      const regex = new RegExp(searchText, "i");
      setFilteredItems(
        searchCategories.filter((item) => regex.test(item.title))
      );
    } else setFilteredItems(searchCategories);
  }, [searchText]);

  const searchFunction = () => {
    RoutesFetch({
      method: "get",
      url: "/searchProduct",
      params: {
        searchText,
      },
    })
      .then((res) => {
        setProductSearchResult(res?.data);
      })
      .catch(() => {});
  };

  const searchDebounceFunction = useCallback(debounce(searchFunction, 300), [
    searchText,
  ]);

  return (
    <div className="w-full h-screen md:h-auto md:w-[600px] p-4">
      <div className="flex flex-row items-end">
        <StandardTextInput
          label={"What you are looking for?"}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        <div
          onClick={() => {
            setSearchMenu(null);
            setShowSearchDrawer(false);
          }}
        >
          <TextComp
            text={"CANCEL"}
            customStyle={
              "text-xs tracking-wide border-b pb-0.2 w-fit ml-4 cursor-pointer"
            }
          />
        </div>
      </div>

      <div className="my-8">
        {productSearchResult.map((item, index) => (
          <div
            key={index}
            className="flex flex-row items-end mb-3 w-fit cursor-pointer"
            onClick={() => {
              setSearchMenu(null);
              setShowSearchDrawer(false);

              navigate(
                `/product/${item?.node?.id?.split("gid://shopify/Product/")[1]}`
              );
            }}
          >
            <LuSearch size={14} className="mr-2" />
            <TextComp
              text={item?.node?.title}
              customStyle={
                "text-[12px] border-b pb-0.2 w-fit font-light tracking-wide"
              }
            />
          </div>
        ))}

        <TextComp
          text={"TRENDING SEARCHES"}
          customStyle={"text-[12px] font-medium tracking-wider mt-6"}
        />

        {filteredItems.length === 0 ? (
          <div className="flex flex-row items-end mt-3 w-fit cursor-pointer">
            <TextComp
              text={"NO SEARCH RESULT"}
              customStyle={
                "text-[12px] border-b w-fit text-darkGray font-light tracking-wide"
              }
            />
          </div>
        ) : (
          filteredItems.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setSearchMenu(null);
                setShowSearchDrawer(false);
                navigate(`/collections/${item.collectionId}`);
              }}
              className="flex flex-row items-end my-3 w-fit cursor-pointer"
            >
              <LuSearch size={14} className="mr-2" />
              <TextComp
                text={item?.title}
                customStyle={
                  "text-[12px] border-b pb-0.2 w-fit font-light tracking-wide"
                }
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default Search;
