import React from "react";
import NavbarAndFooterComp from "../../../../components/NavbarAndFooterComp";
import TextComp from "../../../../components/TextComp";

import { PageHeader } from "../../../../components/PageHeader";
import UserDetails from "../../../../components/UserDetails";

function AccountSettings() {
  return (
    <NavbarAndFooterComp
      children={
        <div>
          <PageHeader text={"ACCOUNT SETTINGS"} />

          <div className="px-5 md:px-28 lg:px-56 py-10">
            <TextComp
              text={"MY PERSONAL DETAILS"}
              customStyle={"text-2xl tracking-widest font-light"}
            />

            <UserDetails />
          </div>
        </div>
      }
    />
  );
}

export default AccountSettings;
