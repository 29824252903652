import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function CustomAccordion({ title, details }) {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {/* <p className="text-black text-xs font-semibold tracking-widest">
            {" "} */}
          {title}
          {/* </p> */}
        </AccordionSummary>

        <div className="mx-4 mb-5">{details}</div>
      </Accordion>
    </div>
  );
}

export default CustomAccordion;
