export const SuccessTexts = {
  otpSend: "OTP send successfully!",
  detailsUpdate: "Your details updated successfully!",
  addressAdded: "Address added successfully!",
  addressDeleted: "Address deleted successfully!",
  addressEdited: "Address edited successfully!",
  SharedLinkCopies: "Sharable product link copied to clipboard!",
  ProductAddedToBag: "Product added to bag!",
  orderPlaced: "Your order placed successfully!",
  deliveryStatusUpdated: "Delivery status updated successfully!",
  paymentStatusUpdated: "Payment status updated successfully!",
  couponCreated: "Coupon created successfully!",
  couponDeleted: "Coupon deleted successfully!",
  requestRaised: "Your request raised successfully!",
  returnProcessCompleted: "Return process completed!",
  customisationStatusUpdated: "Customisation status updated successfully!",
};
