import React, { useEffect, useState } from "react";

import { IoCloseOutline } from "react-icons/io5";
import { Colors } from "../../../constants/Colors";
import { RoutesFetch } from "../../../config/RoutesFetch";
import { ErrorTexts } from "../../../constants/ErrorTexts";
import TextComp from "../../../components/TextComp";
import { OutlineTextInput } from "../../../components/TextInput";
import RouteWaiter from "../../../components/RouteWaiter";
import InfiniteScrollComp from "../../../components/InfiniteScrollComp";
import moment from "moment";
import { useSelector } from "react-redux";
import { MdCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import Button, { OutlineButton } from "../../../components/Button";

function SelectCouponsModal({
  open,
  setOpen,
  loading,
  setLoading,
  couponCode,
  setCouponCode,
}) {
  const userData = useSelector((state) => state?.userData?.data);

  const [errorText, setErrorText] = useState("");
  const [data, setData] = useState(null);

  const [couponCodeText, setCouponCodeText] = useState("");

  return (
    <div className="my-24 flex items-center justify-center">
      <div className="bg-white mx-5 md:mx-0 w-full md:w-2/3 lg:w-1/2 p-2">
        <div className="flex flex-row justify-between items-center m-2 my-4 md:m-4">
          <TextComp
            text={"APPLY COUPON"}
            customStyle={"text-lg font-medium tracking-wider"}
          />

          <IoCloseOutline
            color={Colors.black}
            size={40}
            className="cursor-pointer hover:p-1 duration-500"
            onClick={() => setOpen(false)}
          />
        </div>

        <div className="m-2 md:m-10">
          <OutlineTextInput
            label={"Enter Coupon"}
            value={couponCodeText}
            onChange={(e) => {
              setCouponCodeText(e.target.value);
            }}
          />
          <div className="mt-5 w-full">
            <Button
              btnText={"APPLY COUPON"}
              onPress={
                couponCodeText === ""
                  ? null
                  : () => {
                      setCouponCode(couponCodeText);
                      setOpen(false);
                    }
              }
            />
          </div>

          <TextComp text="OR" customStyle={"text-darkGray p-2 text-sm mt-2"} />

          <TextComp
            text="SELECT COUPON"
            customStyle={
              "text-black font-semibold tracking-widest text-xs px-2 mb-2"
            }
            onClick={() => {
              setOpen(false);
            }}
          />

          <InfiniteScrollComp
            url="/getAllDiscountToUser"
            responseDataName="discountData"
            setLoading={setLoading}
            setErrorText={setErrorText}
            data={data}
            setData={setData}
            valueOfUseEffectArray={[]}
            // setResponseFromApi={setResponseFromApi}
            children={
              <RouteWaiter
                loading={loading}
                data={data}
                noDataChildren={
                  <div className="m-2 md:m-5">
                    <TextComp
                      text={"No coupons available"}
                      customStyle={
                        "text-xs font-light text-darkGray tracking-wide"
                      }
                    />
                  </div>
                }
                error={errorText}
                errorChildren={
                  <div className="m-2 md:m-5">
                    <TextComp
                      text={errorText}
                      customStyle={"text-xs font-light text-red tracking-wide"}
                    />
                  </div>
                }
                children={data?.map((item, index) => (
                  <div className="" key={index}>
                    <ShowDiscountsComp
                      item={item}
                      setOpen={setOpen}
                      setCouponCode={setCouponCode}
                      couponCode={couponCode}
                    />
                  </div>
                ))}
              />
            }
          />
        </div>
      </div>
    </div>
  );
}

const ShowDiscountsComp = ({ item, setOpen, setCouponCode, couponCode }) => {
  const [showTerms, setShowTerms] = useState(false);

  return (
    <div
      onClick={() => {
        if (item.couponCode === couponCode) {
          setCouponCode("");
        } else {
          setCouponCode(item?.couponCode);
          setOpen(false);
        }
      }}
      className="my-4 border border-lightGray bg-lightGray shadow rounded p-2 md:p-5 text-sm tracking-widest"
    >
      <div className="flex">
        {item.couponCode === couponCode ? (
          <MdCheckBox size={24} className="mr-3" />
        ) : (
          <MdOutlineCheckBoxOutlineBlank size={24} className="mr-3" />
        )}
        <div className="w-full">
          <TextComp text={item?.couponName} customStyle={"font-medium mb-1"} />

          <div className="flex flex-row items-center justify-between">
            <TextComp
              text={item?.couponCode}
              customStyle={"font-light text-darkGray text-xs"}
            />
            <div
              onClick={(e) => {
                e.stopPropagation();
                setShowTerms(!showTerms);
              }}
            >
              <TextComp
                text={"view T&C"}
                customStyle={
                  "text-darkGray font-light text-[10px] tracking-wide underline text-right cursor-pointer"
                }
              />
            </div>
          </div>
        </div>
      </div>

      {showTerms === false ? null : (
        <div className="pt-2">
          <TextComp
            text={item?.discountDescription}
            customStyle={"text-darkGray font-light text-[10px] tracking-wide"}
          />
        </div>
      )}
    </div>
  );
};

export default SelectCouponsModal;
