import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";

function NavbarAndFooterComp({ children }) {
  return (
    <div>
      <Navbar />

      <div className="relative mt-20 md:mt-24 min-h-screen">{children}</div>

      <Footer />
    </div>
  );
}

export default NavbarAndFooterComp;
