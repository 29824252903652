import React, { useState } from "react";
import NavbarAndFooterComp from "../../../../components/NavbarAndFooterComp";
import TextComp from "../../../../components/TextComp";
import { PageHeader } from "../../../../components/PageHeader";

import Loader from "../../../../components/Loader";
import OffersForm from "../OffersForm";
import { useLocation, useNavigate } from "react-router-dom";

function CreateCoupon() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();
  const discountData = state?.discountData ? state?.discountData : null;

  return (
    <NavbarAndFooterComp
      children={
        <div>
          <PageHeader text={discountData ? "EDIT COUPON" : "CREATE COUPON"} />
          <div className="my-10 mx-5 md:mx-10">
            {/* <TextComp
              text={discountData ? "EDIT COUPON" : "CREATE COUPON"}
              customStyle={"text-lg font-medium tracking-widest"}
            /> */}

            <OffersForm
              setLoading={setLoading}
              discountData={discountData}
              navigate={navigate}
            />
          </div>

          <Loader loading={loading} />
        </div>
      }
    />
  );
}

export default CreateCoupon;
