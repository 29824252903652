import React, { useEffect, useState } from "react";
import NavbarAndFooterComp from "../../../../components/NavbarAndFooterComp";
import { PageHeader } from "../../../../components/PageHeader";
import TextComp from "../../../../components/TextComp";
import { CiEdit } from "react-icons/ci";
import { AiOutlineDelete } from "react-icons/ai";

import { FcHome } from "react-icons/fc";
import CustomModal from "../../../../components/Modal";
import AddressForm from "./AddressForm";
import Loader from "../../../../components/Loader";
import { RoutesFetch } from "../../../../config/RoutesFetch";
import {
  errorToastify,
  successToastify,
} from "../../../../components/Toastify";
import { ErrorTexts } from "../../../../constants/ErrorTexts";
import { SuccessTexts } from "../../../../constants/SuccessTexts";
import { removeData } from "../../../../config/removeData";
import DialogBox from "../../../../components/DialogBox";
import RouteWaiter from "../../../../components/RouteWaiter";
import InfiniteScrollComp from "../../../../components/InfiniteScrollComp";

function AddressBook() {
  const [openAddressModal, setOpenAddressModal] = useState(false);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const [isFocused, setIsFocused] = useState(false);

  // useEffect(() => {
  //   setLoading(true);

  //   RoutesFetch({
  //     method: "get",
  //     url: "/getUserAddress",
  //   })
  //     .then((response) => {
  //       if (response.msg === "fail") {
  //         errorToastify(ErrorTexts.sww);
  //       } else {
  //         setData(response.addressData);
  //       }
  //     })
  //     .catch((err) => {
  //       errorToastify(ErrorTexts.sww);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, [isFocused]);

  const AddAddressCard = () => {
    return (
      <div
        onClick={() => setOpenAddressModal(true)}
        className="my-5 mx-4 p-4 bg-white shadow col-span-1 flex flex-col justify-center items-center cursor-pointer"
      >
        <div className="w-fit bg-lightGray p-4 rounded-full">
          <FcHome size={60} />
        </div>
        <TextComp
          text={"ADD NEW ADDRESS"}
          customStyle={
            "text-xs font-medium tracking-wide mt-4 border-b pb-0.5 w-fit"
          }
        />
      </div>
    );
  };

  return (
    <NavbarAndFooterComp
      children={
        <div>
          <InfiniteScrollComp
            url="/getUserAddress"
            responseDataName="addressData"
            setLoading={setLoading}
            errorToastifyBoolean={true}
            data={data}
            setData={setData}
            valueOfUseEffectArray={[isFocused]}
            children={
              <div>
                <PageHeader text={"ADDRESS BOOK"} />

                <RouteWaiter
                  loading={loading}
                  data={data}
                  noDataChildren={
                    <div className="mx-5 lg:mx-10 md:grid md:grid-cols-2 lg:grid-cols-3">
                      <AddAddressCard />
                    </div>
                  }
                  errorChildren={
                    <div className="mx-5 lg:mx-10 md:grid md:grid-cols-2 lg:grid-cols-3">
                      <AddAddressCard />
                    </div>
                  }
                  children={
                    <div className="mx-5 lg:mx-10 md:grid md:grid-cols-2 lg:grid-cols-3">
                      <AddAddressCard />
                      {data?.map((item, index) => (
                        <AddressCard
                          item={item}
                          index={index}
                          setLoading={setLoading}
                          data={data}
                          setData={setData}
                          setIsFocused={setIsFocused}
                          isFocused={isFocused}
                        />
                      ))}
                    </div>
                  }
                />
              </div>
            }
          />

          <CustomModal
            open={openAddressModal}
            setOpen={setOpenAddressModal}
            children={
              <AddressForm
                setOpen={setOpenAddressModal}
                setIsFocused={setIsFocused}
                isFocused={isFocused}
              />
            }
          />
        </div>
      }
    />
  );
}

const AddressCard = ({
  item,
  index,
  setLoading,
  data,
  setData,
  setIsFocused,
  isFocused,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditAddressModal, setOpenEditAddressModal] = useState(false);

  const deleteAddress = (id) => {
    setLoading(true);

    RoutesFetch({
      method: "post",
      url: "/deleteUserAddress",
      data: {
        addressId: id,
        defaultAddress: item.default,
      },
    })
      .then((response) => {
        if (response.msg === "fail") {
          errorToastify(ErrorTexts.sww);
        } else if (response.msg === "cannotDeleteDefaultAddress") {
          errorToastify(ErrorTexts.cannotDeleteDefaultAddress);
        } else {
          removeData({ data, setData, value: id, keyName: "_id" });
          successToastify(SuccessTexts.addressDeleted);
        }
        setOpenDialog(false);
      })
      .catch((err) => {
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const CardDetails = ({ title, value }) => {
    return (
      <div className="flex flex-row items-start mb-4 justify-between">
        <TextComp text={title} customStyle={"mr-5"} />
        <TextComp text={value} customStyle={"text-darkGray text-right"} />
      </div>
    );
  };

  return (
    <>
      <div className="my-5 mx-4 bg-white shadow col-span-1 pb-4" key={index}>
        <div className="flex flex-row justify-between items-center mb-2">
          {item.default === true ? (
            <TextComp
              text={"PRIMARY"}
              customStyle={
                "text-xs font-medium tracking-wide bg-lightGray p-2 rounded w-fit m-2"
              }
            />
          ) : null}
          <div className="flex flex-row items-center justify-end w-full my-2 py-2">
            <div onClick={() => setOpenEditAddressModal(true)}>
              <CiEdit
                size={20}
                className="mr-2 text-themeColorBlue cursor-pointer"
              />
            </div>
            <div onClick={() => setOpenDialog(true)}>
              <AiOutlineDelete
                size={20}
                className="mr-2 text-red cursor-pointer"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center px-4 text-sm font-medium tracking-wide">
          <CardDetails
            title={"Name"}
            value={`${item.first_name} ${item.last_name}`}
          />
          <CardDetails
            title={"Address"}
            value={`${item.address1}, ${
              item.address2 === "" ? "" : `${item.address2},`
            } ${item.city}, ${item.province}, ${item.zip}`}
          />

          <CardDetails title={"Contact Number"} value={`${item.phone}`} />
        </div>
      </div>

      <DialogBox
        title={"Do you really want to remove this address!"}
        open={openDialog}
        setOpen={setOpenDialog}
        agreeFunc={() => deleteAddress(item._id)}
      />

      <CustomModal
        open={openEditAddressModal}
        setOpen={setOpenEditAddressModal}
        children={
          <AddressForm
            setOpen={setOpenEditAddressModal}
            setIsFocused={setIsFocused}
            isFocused={isFocused}
            edit={true}
            data={item}
          />
        }
      />
    </>
  );
};

export default AddressBook;
