import React, { useState } from "react";
import TextComp from "../../components/TextComp";

import { useNavigate, useLocation } from "react-router-dom";
import { logoutFunc } from "../../config/LogoutFunc";
import { useDispatch } from "react-redux";

import Loader from "../../components/Loader";

function ProfileMenu({ setProfileMenu }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = location;

  const [loading, setLoading] = useState(false);

  const MenuItem = ({ text, link }) => {
    return (
      <div
        onClick={() => {
          navigate(link);
        }}
      >
        <TextComp
          text={text}
          customStyle={
            "text-center md:text-left py-4 px-5 text-sm font-medium border-b border-lightGray cursor-pointer"
          }
        />
      </div>
    );
  };

  return (
    <div className="w-full mb-10 md:mb-0 md:w-56">
      <MenuItem text={"MY ACCOUNT"} link={"/myaccount"} />
      <MenuItem text={"MY ORDERS"} link={"/myorders"} />
      <MenuItem text={"MY CUSTOMISATION"} link={"/customisationorders"} />
      <MenuItem text={"ACCOUNT SETTINGS"} link={"/accountsettings"} />
      <MenuItem text={"ADDRESS BOOK"} link={"/addressbook"} />
      <MenuItem text={"SAVED ITEMS"} link={"/saveditems"} />

      <div
        onClick={() => {
          logoutFunc({
            navigate,
            pathname,
            dispatch,
            setLoading,
            setProfileMenu,
            cookies: {},
          });
        }}
      >
        <TextComp
          text={"SIGN OUT"}
          customStyle={
            "py-4 px-5 text-center md:text-left text-xs font-light underline cursor-pointer tracking-wider"
          }
        />
      </div>

      <Loader loading={loading} />
    </div>
  );
}

export default ProfileMenu;
