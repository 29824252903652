import React, { useState } from "react";
import NavbarAndFooterComp from "../../../../components/NavbarAndFooterComp";
import { PageHeader } from "../../../../components/PageHeader";
import InfiniteScrollComp from "../../../../components/InfiniteScrollComp";
import { useNavigate } from "react-router-dom";
import RouteWaiter from "../../../../components/RouteWaiter";
import { FaBox } from "react-icons/fa";
import { Colors } from "../../../../constants/Colors";
import TextComp from "../../../../components/TextComp";
import moment from "moment";

function CustomisationOrders() {
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(true);
  const [customisationData, setCustomisationData] = useState(null);

  const navigate = useNavigate();
  return (
    <NavbarAndFooterComp
      children={
        <div>
          <PageHeader text={"MY CUSTOMISATION"} />

          <InfiniteScrollComp
            url="/getUserCustomisation"
            responseDataName="customisationData"
            setLoading={setLoading}
            setErrorText={setErrorText}
            data={customisationData}
            setData={setCustomisationData}
            children={
              <RouteWaiter
                loading={loading}
                error={errorText}
                data={customisationData}
                children={
                  <div>
                    {customisationData?.map((item, index) => (
                      <CardsComp item={item} key={index} />
                    ))}
                  </div>
                }
              />
            }
          />
        </div>
      }
    />
  );
}

const CardsComp = ({ item }) => {
  return (
    <div className="bg-white shadow m-4 p-4 md:m-10 md:p-10 rounded cursor-pointer">
      <div className="flex items-center">
        <div className="rounded-full bg-black p-3">
          <FaBox color={Colors.white} size={20} />
        </div>
        <div>
          <TextComp
            text={item?.status?.toUpperCase()}
            customStyle={"mx-4 text-sm font-medium tracking-widest"}
          />
          <TextComp
            text={`Last updated on ${moment(item?.updatedAt).format(
              "DD-MM-YYYY hh:mm"
            )}`}
            customStyle={"mx-4 text-[9px] font-light text-darkGray"}
          />
        </div>
      </div>

      <div className="mt-5 flex items-center">
        <TextComp
          text={"Product Title -"}
          customStyle={"text-sm tracking-wider text-darkGray"}
        />
        <TextComp
          text={item?.productTitle}
          customStyle={"text-sm tracking-wider ml-2"}
        />
      </div>
      <div className="mt-5 flex items-center">
        <TextComp
          text={"Design Description -"}
          customStyle={"text-sm tracking-wider text-darkGray"}
        />
        <TextComp
          text={item?.designDescription}
          customStyle={"text-sm tracking-wider ml-2"}
        />
      </div>
    </div>
  );
};

export default CustomisationOrders;
