import React from "react";

import Drawer from "@mui/material/Drawer";
import { IoCloseCircle } from "react-icons/io5";
import { Colors } from "../../constants/Colors";

function CustomDrawer({ position, open, toggleDrawerFunc, state, children }) {
  return (
    <Drawer
      anchor={position}
      open={open}
      onClose={() => toggleDrawerFunc(false, state)}
      transitionDuration={500}
      sx={{
        backdropFilter: "blur(15px)",
      }}
    >
      <div className="bg-white w-screen md:w-[500px] lg:w-[600px]">
        <div className="flex flex-col items-end m-4">
          <IoCloseCircle
            color={Colors.black}
            size={60}
            className="cursor-pointer hover:p-1 duration-500"
            onClick={() => toggleDrawerFunc(false, state)}
          />
        </div>
        {children}
      </div>
    </Drawer>
  );
}

export default CustomDrawer;
