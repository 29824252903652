import React, { useEffect, useState } from "react";
import TextComp from "../../../components/TextComp";

import { RoutesFetch } from "../../../config/RoutesFetch";
import { errorToastify } from "../../../components/Toastify";
import { ErrorTexts } from "../../../constants/ErrorTexts";

import CheckoutCommonFlow from "../checkoutCommonFlow";

function SingleProductCheckout({ setShowSingleCheckoutDrawer }) {
  const [loading, setLoading] = useState(false);
  const [bagData, setBagData] = useState(null);
  const [updateBagData, setUpdateBagData] = useState(0);

  useEffect(() => {
    getBagProducts();
  }, [updateBagData]);

  const getBagProducts = () => {
    setLoading(true);

    RoutesFetch({
      method: "get",
      url: "/getProductDetailsForSingleCheckout",
    })
      .then((res) => {
        if (res.msg === "fail") {
          errorToastify(ErrorTexts.sww);
        } else {
          const bag = res?.bagData;
          let productDetails = res?.productData?.products;

          let mergedData = [];

          for (let i = 0; i < bag.length; i++) {
            for (let j = 0; j < productDetails.length; j++) {
              if (bag[i].productId === productDetails[j].id) {
                mergedData.push({ ...bag[i], ...productDetails[j] });

                productDetails.push(productDetails[j]);
                productDetails.splice(j, 1);
                break;
              }
            }
          }

          for (let i = 0; i < mergedData.length; i++) {
            let variants;
            for (let j = 0; j < mergedData[i].variants.length; j++) {
              if (mergedData[i].variantId === mergedData[i]?.variants[j].id) {
                variants = mergedData[i]?.variants[j];
                mergedData[i].variants = variants;
                break;
              }
            }
          }

          setBagData([...mergedData]);
        }
      })
      .catch((err) => {
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="my-2 mb-10 mx-5 md:mx-10 lg:mx-20">
      <TextComp
        text={"CHECKOUT"}
        customStyle={"text-2xl font-medium tracking-widest"}
      />

      <CheckoutCommonFlow
        loading={loading}
        bagData={bagData}
        setLoading={setLoading}
        setBagDataFromParent={setBagData}
        fromSingleCheckout={true}
        setUpdateBagData={setUpdateBagData}
        updateBagData={updateBagData}
        setDrawer={setShowSingleCheckoutDrawer}
      />
    </div>
  );
}

export default SingleProductCheckout;
