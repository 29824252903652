import { createSlice } from "@reduxjs/toolkit";

export const LoadingDataSlice = createSlice({
  name: "LoadingData",
  initialState: {
    loading: false,
  },
  reducers: {
    setLoadingData: (state, action) => {
      const data = action.payload;
      state.data = data;
    },
  },
});

// this is for dispatch
export const { setLoadingData } = LoadingDataSlice.actions;

// this is for configureStore
export default LoadingDataSlice.reducer;
