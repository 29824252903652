import React from "react";
import TextComp from "../../../components/TextComp";

function DescriptionText({ text }) {
  return (
    <TextComp
      customStyle={"py-2 text-sm text-darkGray mt-2 font-light"}
      text={text}
    />
  );
}

export default DescriptionText;
