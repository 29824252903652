import React from "react";
import TextComp from "../../../components/TextComp";

function SubHeaders({ text }) {
  return (
    <TextComp
      text={text}
      customStyle={"text-sm text-black font-semibold tracking-wider"}
    />
  );
}

export default SubHeaders;
