import React, { useState } from "react";
import NavbarAndFooterComp from "../../../components/NavbarAndFooterComp";
import SelectInput from "../../../components/TextInput/SelectInput";
import { CustomisationStatuses } from "../../../constants/DeliveryStatuses";
import InfiniteScrollComp from "../../../components/InfiniteScrollComp";
import RouteWaiter from "../../../components/RouteWaiter";
import CustomAccordion from "../../../components/CustomAccordion";
import TextComp from "../../../components/TextComp";
import Button from "../../../components/Button";
import DialogBox from "../../../components/DialogBox";
import { RoutesFetch } from "../../../config/RoutesFetch";
import { errorToastify, successToastify } from "../../../components/Toastify";
import { ErrorTexts } from "../../../constants/ErrorTexts";
import { SuccessTexts } from "../../../constants/SuccessTexts";

function CustomisationRequest() {
  const [filter, setFilter] = useState("pending");
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(true);
  const [customisationData, setCustomisationData] = useState(null);

  return (
    <NavbarAndFooterComp
      children={
        <div className="mx-5 md:mx-10 py-10">
          <SelectInput
            label={"Search by customisation status"}
            values={CustomisationStatuses}
            id="title"
            onChange={(e) => setFilter(e.target.value)}
            defaultValue={"pending"}
          />

          <div>
            <InfiniteScrollComp
              url="/getAllCustomisation"
              responseDataName="customisationData"
              params={{ customisationStatus: filter }}
              setLoading={setLoading}
              setErrorText={setErrorText}
              data={customisationData}
              setData={setCustomisationData}
              valueOfUseEffectArray={[filter]}
              children={
                <RouteWaiter
                  loading={loading}
                  error={errorText}
                  data={customisationData}
                  children={
                    <div>
                      {customisationData?.map((item, index) => (
                        <CardsComp
                          item={item}
                          key={index}
                          setLoading={setLoading}
                          setCustomisationData={setCustomisationData}
                          customisationData={customisationData}
                          index={index}
                        />
                      ))}
                    </div>
                  }
                />
              }
            />
          </div>
        </div>
      }
    />
  );
}

const CardsComp = ({
  item,
  index,
  setLoading,
  customisationData,
  setCustomisationData,
}) => {
  const [updateCustomisationStatus, setUpdateCustomisationStatus] = useState(
    item?.status
  );
  const [
    updateCustomisationStatusDialogBox,
    setUpdateCustomisationStatusDialogBox,
  ] = useState(false);

  const updateCustomisationStatusFunc = () => {
    setLoading(true);

    RoutesFetch({
      method: "post",
      url: "/updateCustomisationStatus",
      data: {
        customisationId: item?._id,
        customisationStatus: updateCustomisationStatus,
      },
    })
      .then((res) => {
        if (res?.msg === "fail") {
          errorToastify(ErrorTexts.sww);
        } else {
          customisationData.splice(index, 1);

          setCustomisationData([...customisationData]);
          successToastify(SuccessTexts.customisationStatusUpdated);
        }
      })
      .catch(() => {
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        setUpdateCustomisationStatusDialogBox(false);
        setLoading(false);
      });
  };

  const FlexDiv = ({ title, value }) => {
    return (
      <div className="flex flex-row items-center justify-between text-sm tracking-wide font-medium mb-2">
        <TextComp text={title} />
        <TextComp text={value} />
      </div>
    );
  };

  return (
    <div className="bg-white shadow my-10 py-5 px-5 cursor-pointer rounded">
      <div className="py-2">
        <CustomAccordion
          title={"User Details"}
          details={
            <div>
              {!item?.userData?.firstName ? null : (
                <FlexDiv
                  title={"Name"}
                  value={`${item?.userData?.firstName} ${item?.userData?.lastName}`}
                />
              )}
              {!item?.userData?.email ? null : (
                <FlexDiv title={"Email"} value={`${item?.userData?.email}`} />
              )}
              <FlexDiv
                title={"Mobile Number"}
                value={`${item?.userData?.mobileNumber}`}
              />
            </div>
          }
        />
      </div>

      <div className="py-2">
        <CustomAccordion
          title={"Customisation Details"}
          details={
            <div>
              <FlexDiv
                title={"Product Title"}
                value={`${item?.productTitle}`}
              />
              <FlexDiv
                title={"Design Description"}
                value={`${item?.designDescription}`}
              />

              {item?.status !== "delivered" ? (
                <div className="my-5">
                  <SelectInput
                    label={"Update customisation status"}
                    values={CustomisationStatuses}
                    id="demo-controlled-open-select"
                    onChange={(e) =>
                      setUpdateCustomisationStatus(e.target.value)
                    }
                    defaultValue={updateCustomisationStatus}
                  />
                  <Button
                    btnText={"UPDATE CUSTOMISATION STATUS"}
                    customStyle={
                      item?.status === updateCustomisationStatus
                        ? "my-2 bg-darkGray border-darkGray"
                        : "my-2"
                    }
                    onPress={() =>
                      item?.status === updateCustomisationStatus
                        ? null
                        : setUpdateCustomisationStatusDialogBox(true)
                    }
                  />
                </div>
              ) : null}
            </div>
          }
        />
      </div>

      <DialogBox
        title={
          "Confirming the update of Customisation status. Are you sure you'd like to proceed?"
        }
        open={updateCustomisationStatusDialogBox}
        setOpen={setUpdateCustomisationStatusDialogBox}
        agreeFunc={updateCustomisationStatusFunc}
      />
    </div>
  );
};

export default CustomisationRequest;
