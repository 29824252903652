import React from "react";
import TextComp from "../TextComp";

function PageHeader({ text }) {
  return (
    <div className="flex flex-col items-center w-full">
      <TextComp
        text={text.toUpperCase()}
        customStyle={
          "text-4xl font-light tracking-widest py-20 bg-lightGray w-full text-black text-center"
        }
      />
    </div>
  );
}

export { PageHeader };
