import React from "react";

import LostLady from "../../../assets/noDataImg/lost-lady.png";
import TextComp from "../../TextComp";
import Button from "../../Button";
import NavbarAndFooterComp from "../../NavbarAndFooterComp";
import { useNavigate } from "react-router-dom";

function PageNotFound({
  title = "Seems like you're Lost. Let me guide you to the customization page for exactly what you're looking for.",
  text,
}) {
  const naviagte = useNavigate();

  return (
    <NavbarAndFooterComp
      children={
        <div className="w-full h-screen flex flex-row justify-center items-center">
          <div className="w-2/3 flex flex-col justify-center items-center -mt-28 md:-mt-40">
            <img src={LostLady} className="h-60 mb-5" />
            <TextComp
              text={title}
              customStyle={"text-xs tracking-wider text-center"}
            />
            <Button
              onPress={() => {
                naviagte("/customisation");
              }}
              btnText={"Customize Now"}
              customStyle={"w-64 my-5"}
            />
          </div>
        </div>
      }
    />
  );
}

export default PageNotFound;
