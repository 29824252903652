export const DiscountCalculation = (price, MRP) => {
  return Math.ceil(((MRP - price) / MRP) * 100);
};

export const RoundOffFloatIntoTwo = (val) => {
  return val.toFixed(2);
};

export const totalMRPCalculation = ({
  AlreadyAddedAmt,
  productPrice,
  quantity,
}) => {
  return AlreadyAddedAmt + parseInt(productPrice) * quantity;
};

export const totalMRPDiscountCalculation = ({
  AlreadyAddedAmt,
  productPrice,
  productComparePrice,
  quantity,
}) => {
  return (
    AlreadyAddedAmt +
    (parseInt(productComparePrice) - parseInt(productPrice)) * quantity
  );
};

export const totalAmtToBePaidCalculation = ({
  totalMRPVariable,
  totalMRPDiscountVariable,
}) => {
  return totalMRPVariable - totalMRPDiscountVariable;
};
