import React from "react";
import Modal from "@mui/material/Modal";

function CustomModal({ open, setOpen, children, atCenter }) {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {atCenter === true ? (
        <div
          style={{ outline: "none" }}
          className="w-full h-screen px-5 md:px-0 flex items-center justify-center"
        >
          {children}
        </div>
      ) : (
        <div
          style={{ outline: "none" }}
          className={"w-full h-screen overflow-y-scroll scrollbar-hide"}
        >
          {children}
        </div>
      )}
    </Modal>
  );
}

export default CustomModal;
