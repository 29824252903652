import React, { useState } from "react";
import { useParams } from "react-router-dom";
import NavbarAndFooterComp from "../../components/NavbarAndFooterComp";
import InfiniteScrollCompProductList from "../../components/InfiniteScrollComp/ProductList";
import RouteWaiter from "../../components/RouteWaiter";
import ProductSkeleton from "../../components/ProductSkeleton";
import ProductList from "../../components/ProductList";

function Collections() {
  const { collectionId } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [errorText, setErrorText] = useState("");

  return (
    <NavbarAndFooterComp
      children={
        <InfiniteScrollCompProductList
          url="/getProductsByCollection"
          params={{ collectionId }}
          responseDataName="productData"
          setLoading={setLoading}
          setErrorText={setErrorText}
          data={data}
          setData={setData}
          valueOfUseEffectArray={[collectionId]}
          children={
            <RouteWaiter
              loading={loading}
              error={errorText}
              data={data}
              children={<ProductList data={data} />}
              loaderComp={<ProductSkeleton numberofSkeletonToShow={8} />}
            />
          }
        />
      }
    />
  );
}

export default Collections;
