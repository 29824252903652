import React, { useEffect, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { Colors } from "../../../../../../constants/Colors";
import TextComp from "../../../../../../components/TextComp";
import DisplayProducts from "../../DisplayProducts";
import CustomCheckbox from "../../../../../../components/Checkbox";
import Button from "../../../../../../components/Button";
import SelectInput from "../../../../../../components/TextInput/SelectInput";
import { RoutesFetch } from "../../../../../../config/RoutesFetch";
import {
  errorToastify,
  successToastify,
} from "../../../../../../components/Toastify";
import { ErrorTexts } from "../../../../../../constants/ErrorTexts";
import Loader from "../../../../../../components/Loader";
import { SuccessTexts } from "../../../../../../constants/SuccessTexts";

function SelectProductAndReasonModal({
  from,
  open,
  setOpen,
  orderData,
  setUpdateDetails,
  updateDetails,
}) {
  const [returnedProducts, setReturnedProducts] = useState([]);
  const [selectReason, setSelectReason] = useState("");
  const [selectOptions, setSelectOptions] = useState("");
  const [loading, setLoading] = useState(false);

  const sendRequest = () => {
    if (
      returnedProducts?.length === 0 ||
      selectReason === "" ||
      selectOptions === ""
    ) {
      errorToastify(ErrorTexts.requiredFields);
    } else {
      setLoading(true);

      RoutesFetch({
        method: "post",
        url: "/raiseReturnRequest",
        data: {
          orderId: orderData?._id,
          returnedType: selectOptions,
          returnedReason: selectReason,
          returnedProducts: returnedProducts,
        },
      })
        .then((res) => {
          if (res?.msg === "fail") {
            errorToastify(ErrorTexts.sww);
          } else {
            setOpen(false);
            successToastify(SuccessTexts.requestRaised);
            setUpdateDetails(updateDetails + 1);
          }
        })
        .catch(() => {
          errorToastify(ErrorTexts.sww);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const reasons =
    from === "cancel"
      ? [
          "Incorrect size ordered",
          "Product not required anymore",
          "Ordered by mistake",
          "Need to change delivery address",
          "Better price avaiable",
          "Any other Reason",
        ]
      : [
          { label: "Accidental order", value: "Accidental order" },
          { label: "Better price avaiable", value: "Better price avaiable" },
          {
            label: "Missing parts or accessories",
            value: "Missing parts or accessories",
          },
          {
            label: "Different from what was ordered",
            value: "Different from what was ordered",
          },
          {
            label: "Product not required anymore",
            value: "Product not required anymore",
          },
          { label: "Incorrect size ordered", value: "Incorrect size ordered" },
          { label: "Ordered by mistake", value: "Ordered by mistake" },
          { label: "Any other Reason", value: "Any other Reason" },
        ];

  const options =
    from === "cancel"
      ? [{ label: "CANCEL", value: "cancel" }]
      : [
          { label: "EXCHANGE", value: "exchange" },
          { label: "RETURN", value: "return" },
        ];

  return (
    <div className="my-24 flex items-center justify-center">
      <div className="bg-white md:mx-0 w-full md:w-2/3 lg:w-1/2 p-2 px-4">
        <div className="flex flex-row items-center justify-between my-4">
          <TextComp
            text={
              from === "cancel" ? "CANCEL ORDER" : "RETURN / EXCHANGE ORDER"
            }
            customStyle={"text-sm text-darkGray tracking-wide"}
          />
          <IoCloseOutline
            color={Colors.black}
            size={40}
            className="cursor-pointer hover:p-1 duration-500"
            onClick={() => setOpen(false)}
          />
        </div>

        <div className="mt-5">
          <TextComp
            text={"Select Product"}
            customStyle={"text-sm tracking-wide mb-5"}
          />
          {orderData?.checkoutData?.productDetails.map((productItem, index) => (
            <SelectProducts
              key={index}
              productItem={productItem}
              orderData={orderData}
              setReturnedProducts={setReturnedProducts}
              returnedProducts={returnedProducts}
            />
          ))}
        </div>

        <div className="mt-5">
          <SelectInput
            label={"What do you want to do with this order?"}
            values={options}
            onChange={(e) => setSelectOptions(e.target.value)}
          />
        </div>

        <div className="mt-5">
          <SelectInput
            label={"Select Reason"}
            values={reasons}
            onChange={(e) => setSelectReason(e.target.value)}
          />
        </div>

        <div className="mt-5">
          {returnedProducts?.length === 0 ? (
            <Button
              btnText="PROCEED"
              customStyle={"bg-darkGray border-darkGray"}
            />
          ) : (
            <Button btnText="PROCEED" onPress={sendRequest} />
          )}
        </div>
      </div>
      <Loader loading={loading} />
    </div>
  );
}

const SelectProducts = ({
  productItem,
  orderData,
  setReturnedProducts,
  returnedProducts,
}) => {
  const [select, setSelect] = useState(false);

  useEffect(() => {
    if (select === true) {
      returnedProducts?.length === 0
        ? setReturnedProducts([productItem])
        : setReturnedProducts([...returnedProducts, productItem]);
    } else {
      for (let i = 0; i < returnedProducts?.length; i++) {
        if (productItem?._id === returnedProducts[i]?._id) {
          returnedProducts.splice(i, 1);
          setReturnedProducts([...returnedProducts]);
          break;
        }
      }
    }
  }, [select]);

  return (
    <div className="flex">
      <CustomCheckbox
        value={select}
        onChange={(e) => {
          setSelect(!select);
        }}
      />

      <DisplayProducts productItem={productItem} orderData={orderData} />
    </div>
  );
};

export default SelectProductAndReasonModal;
