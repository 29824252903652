import React, { useState } from "react";

import NavbarAndFooterComp from "../../components/NavbarAndFooterComp";
import TextComp from "../../components/TextComp";

import MobileNumberScreen from "./MobileNumberScreen";
import Otpscreen from "./OtpScreen";
import Loader from "../../components/Loader";

function Login() {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <NavbarAndFooterComp
      children={
        <div className="py-10 md:py-20 px-5 md:px-10 lg:px-20 flex flex-col items-center">
          <TextComp
            text={"MY GENIEASE ACCOUNT"}
            customStyle={
              "text-xl md:text-3xl lg:text-4xl font-light tracking-widest pb-2 md:pb-4 text-center"
            }
          />
          <TextComp
            text={"CONTINUE WITH YOUR MOBILE NUMBER"}
            customStyle={
              "text-xl md:text-3xl lg:text-4xl font-light tracking-widest pb-2 md:pb-4 text-center"
            }
          />

          {otpSent === false ? (
            <MobileNumberScreen
              setMobileNumber={setMobileNumber}
              mobileNumber={mobileNumber}
              setOtpSent={setOtpSent}
              setLoading={setLoading}
            />
          ) : (
            <Otpscreen
              mobileNumber={mobileNumber}
              otpValue={otpValue}
              setOtpValue={setOtpValue}
              setLoading={setLoading}
              setOtpSent={setOtpSent}
              otpSent={otpSent}
            />
          )}

          <div className="mt-20 w-full">
            <TextComp
              text={"JOIN MY GENIEASE"}
              customStyle={
                "text-3xl md:text-3xl lg:text-4xl pb-5 md:pb-10 font-light tracking-widest text-center"
              }
            />

            <div className="lg:grid grid-cols-3 w-full">
              <div className="flex flex-col items-center p-10 col-span-1 border border-lightGray text-center">
                <TextComp
                  text={"TRACK YOUR ORDERS"}
                  customStyle={"font-medium pb-2"}
                />
                <TextComp
                  text={"Follow your orders every step of the way."}
                  customStyle={"text-xs font-light"}
                />
              </div>

              <div className="flex flex-col items-center p-10 col-span-1 border border-lightGray text-center">
                <TextComp
                  text={"STREAMLINE CHECKOUT"}
                  customStyle={"font-medium pb-2"}
                />
                <TextComp
                  text={
                    "Check out faster with saved addresses and payment methods."
                  }
                  customStyle={"text-xs font-light"}
                />
              </div>

              <div className="flex flex-col items-center p-10 col-span-1 border border-lightGray text-center">
                <TextComp
                  text={"BOOK AN APPOINTMENT"}
                  customStyle={"font-medium pb-2"}
                />
                <TextComp
                  text={
                    "Plan a personalized visit at a store or virtually in the Digital Showroom."
                  }
                  customStyle={"text-xs font-light"}
                />
              </div>
            </div>
          </div>

          <Loader loading={loading} />
        </div>
      }
    />
  );
}

export default Login;
