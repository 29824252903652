const environment = "dev";

let EnvConfig = {
  baseUrl: "/genieaseBackend",
  websiteDomainUrl: "localhost:3000",
  razorpayKeyId: "rzp_live_bya7XxBz87mHnQ",
};

if (environment === "dev") {
  EnvConfig = {
    baseUrl: "https://api.develop-testify.me/genieaseBackend",
    websiteDomainUrl: "https://develop-testify.me",
    razorpayKeyId: "rzp_live_bya7XxBz87mHnQ",
  };
}

export { EnvConfig, environment };
