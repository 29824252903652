import { Modal } from "@mui/material";
import React from "react";
import { Colors } from "../../constants/Colors";

import { RotatingSquare, ThreeDots } from "react-loader-spinner";

function Loader({ loading }) {
  return (
    <Modal
      open={loading}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="flex items-center justify-center"
    >
      <RotatingSquare
        visible={true}
        height="100"
        width="100"
        color={Colors.white}
        ariaLabel="rotating-square-loading"
      />
    </Modal>
  );
}

export function InfiniteScrollLoader() {
  return (
    <ThreeDots
      visible={true}
      height="40"
      width="40"
      color={Colors.black}
      radius="9"
      ariaLabel="three-dots-loading"
    />
  );
}

export default Loader;
