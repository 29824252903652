import React from "react";
import NavbarAndFooterComp from "../../../components/NavbarAndFooterComp";
import { Links } from "../../../constants/Links";
import { ImpValues } from "../../../constants/ImpValues";
import { OutlineTextInput } from "../../../components/TextInput";
import Button from "../../../components/Button";
import { PageHeader } from "../../../components/PageHeader";

function ContactForm() {
  return (
    <NavbarAndFooterComp
      children={
        <div>
          <PageHeader text={"GET IN TOUCH"} />

          <div className="mx-5">
            <div className="my-10 items-center">
              <p className="font-light tracking-widest text-center text-xl">
                You can chat with us on our
                <a
                  className="mx-1.5 border-b cursor-pointer border-black"
                  href={Links.instagramLink}
                  target="_blank"
                >
                  Instagram page
                </a>
                and
                <a
                  className="mx-1.5 border-b cursor-pointer border-black"
                  href={Links.whatsAppLink}
                  target="_blank"
                >
                  Whatsapp
                </a>
                by direct messaging us
              </p>
            </div>

            <div className="my-10 items-center">
              <p className="font-light tracking-widest text-center text-xl">
                Call us:
                <a
                  href={`tel:${ImpValues.contactNumber}`}
                  className="mx-1.5 border-b cursor-pointer border-black"
                >
                  {ImpValues.contactNumber}
                </a>
                between 10:30 am - 6:30 pm (Mon - Sat)
              </p>
            </div>

            <div className="my-10 items-center">
              <p className="font-light tracking-widest text-center text-xl">
                E-mail ID:
                <a
                  href={`mailto:${ImpValues.supportMail}`}
                  className="mx-1.5 border-b cursor-pointer border-black"
                >
                  {ImpValues.supportMail}
                </a>
              </p>
            </div>

            <div>
              <p className="text-3xl md:text-4xl lg:text-6xl text-center tracking-wider mb-10">
                Send us a message
              </p>

              <div className="mb-5 lg:mb-10 lg:grid grid-cols-3 mx-5 md:mx-10 lg:mx-20">
                <div className="col-span-1 mb-5 lg:mr-4">
                  <OutlineTextInput label={"Name"} />
                </div>

                <div className="col-span-1 mb-5 lg:mx-4">
                  <OutlineTextInput label={"Email"} />
                </div>

                <div className="col-span-1 lg:ml-4">
                  <OutlineTextInput label={"Contact Number"} />
                </div>
              </div>

              <div className="mx-5 md:mx-10 lg:mx-20">
                <OutlineTextInput
                  label={"Write a message to us..."}
                  multiline={true}
                  minRows={4}
                  maxRows={4}
                />
              </div>

              <div className="my-10 mx-5 md:mx-10 lg:mx-20 flex flex-col items-end justify-end">
                <div className="w-full md:w-fit">
                  <Button btnText={"SEND"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default ContactForm;
