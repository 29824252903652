import React, { useEffect, useState } from "react";

import { IoIosAdd } from "react-icons/io";
import { FiUser } from "react-icons/fi";
import { CgMenuRightAlt, CgShoppingBag } from "react-icons/cg";
import { LuSearch } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Logo from "../../assets/logo/full_logo.png";
import TextComp from "../TextComp";
import CustomDrawer from "../Drawer";
import ProductsMenu from "../../screens/ProductsMenu";
import Contact from "../../screens/Contact";
import Bag from "../../screens/Bag";
import MenuList from "../MenuList";
import ProfileMenu from "../../screens/ProfileMenu";
import Search from "../../screens/Search";

function Navbar() {
  const navigate = useNavigate();
  const userData = useSelector((state) => state?.userData?.data);
  const bagData = useSelector((state) => state?.bagData?.data);

  const [showMenuDrawer, setShowMenuDrawer] = useState(false);
  const [showContactDrawer, setShowContactDrawer] = useState(false);
  const [showBagDrawer, setShowBagDrawer] = useState(false);
  const [showAccountDrawer, setShowAccountDrawer] = useState(false);
  const [showSearchDrawer, setShowSearchDrawer] = useState(false);

  const toggleDrawer = (value, state) => {
    state(value);
  };

  const [profileMenu, setProfileMenu] = useState(null);
  const showProfileMenu = Boolean(profileMenu);

  const profileMenuFunc = (event) => {
    setProfileMenu(event.currentTarget);
  };

  const [searchMenu, setSearchMenu] = useState(null);
  const showSearchMenu = Boolean(searchMenu);

  const SearchMenuFunc = (event) => {
    setSearchMenu(event.currentTarget);
  };

  return (
    <div className="fixed bg-white h-20 -mt-20 md:h-24 md:-mt-24 z-50 w-full flex justify-center">
      <div className="px-4 md:px-5 lg:px-20 grid grid-cols-3 w-full">
        <div className="col-span-1 items-center hidden md:flex">
          <div
            className="cursor-pointer flex flex-row items-center justify-start"
            onClick={() => toggleDrawer(true, setShowContactDrawer)}
          >
            <IoIosAdd size={20} />
            <TextComp
              text={"Contact Us"}
              customStyle={"px-2 text-xs font-semibold"}
            />
          </div>
        </div>

        <div
          className="flex items-center md:justify-center col-span-2 md:col-span-1 mx-2 md-mx-0"
          onClick={() => navigate("/")}
        >
          <img src={Logo} className="h-10 md:h-14 cursor-pointer" />
        </div>

        <div className="flex items-center justify-end col-span-1">
          {!bagData || bagData?.length === 0 ? (
            <div className="cursor-pointer mx-3 lg:mx-4">
              <CgShoppingBag
                className="text=[17px] md:text-[20px]"
                onClick={() => toggleDrawer(true, setShowBagDrawer)}
              />
            </div>
          ) : (
            <div className="flex mx-3 lg:mx-4 relative cursor-pointer">
              <CgShoppingBag
                className="text=[17px] md:text-[20px]"
                onClick={() => toggleDrawer(true, setShowBagDrawer)}
              />

              <TextComp
                text={bagData?.length}
                customStyle={
                  "text-[8px] md:text-xs bg-black text-white rounded-full opacity-50 h-4 w-4 md:h-5 md:w-5 -mt-2 ml-2 md:-mt-3 md:ml-3 flex items-center justify-center absolute"
                }
              />
            </div>
          )}
          <div className="mx-3 lg:mx-4 cursor-pointer">
            <FiUser
              className="text=[17px] md:text-[20px] md:hidden"
              onClick={(event) => {
                userData?.mobileNumberVerified === true
                  ? toggleDrawer(true, setShowAccountDrawer)
                  : navigate("/login");
              }}
            />
            <FiUser
              className="text=[17px] md:text-[20px] hidden md:block"
              onClick={(event) => {
                userData?.mobileNumberVerified === true
                  ? profileMenuFunc(event)
                  : navigate("/login");
              }}
            />
          </div>
          <div className="mx-3 lg:mx-4 cursor-pointer">
            <LuSearch
              className="text=[17px] md:text-[20px] md:hidden"
              onClick={() => toggleDrawer(true, setShowSearchDrawer)}
            />
            <LuSearch
              className="text=[17px] md:text-[20px] hidden md:block"
              onClick={SearchMenuFunc}
            />
          </div>
          <div
            className="mx-3 lg:mx-4 flex flex-row items-center cursor-pointer"
            onClick={() => toggleDrawer(true, setShowMenuDrawer)}
          >
            <CgMenuRightAlt className="text=[17px] md:text-[20px]" />
            <TextComp
              text={"MENU"}
              customStyle={"pl-2 text-xs font-semibold hidden lg:flex"}
            />
          </div>
        </div>
      </div>

      <CustomDrawer
        position={"right"}
        open={showMenuDrawer}
        toggleDrawerFunc={toggleDrawer}
        children={<ProductsMenu setShowMenuDrawer={setShowMenuDrawer} />}
        state={setShowMenuDrawer}
      />

      <CustomDrawer
        position={"right"}
        open={showContactDrawer}
        toggleDrawerFunc={toggleDrawer}
        children={<Contact />}
        state={setShowContactDrawer}
      />
      <CustomDrawer
        position={"right"}
        open={showBagDrawer}
        toggleDrawerFunc={toggleDrawer}
        children={<Bag setShowBagDrawer={setShowBagDrawer} />}
        state={setShowBagDrawer}
      />

      <CustomDrawer
        position={"bottom"}
        open={showAccountDrawer}
        toggleDrawerFunc={toggleDrawer}
        children={<ProfileMenu />}
        state={setShowAccountDrawer}
      />

      <CustomDrawer
        position={"bottom"}
        open={showSearchDrawer}
        toggleDrawerFunc={toggleDrawer}
        children={
          <Search
            setSearchMenu={setSearchMenu}
            setShowSearchDrawer={setShowSearchDrawer}
          />
        }
        state={setShowSearchDrawer}
      />

      <MenuList
        setProfileMenu={setProfileMenu}
        showProfileMenu={showProfileMenu}
        profileMenu={profileMenu}
        children={<ProfileMenu setProfileMenu={setProfileMenu} />}
        customStyle={"mt-6 md:-ml-10 lg:-ml-20"}
      />

      <MenuList
        setProfileMenu={setSearchMenu}
        showProfileMenu={showSearchMenu}
        profileMenu={searchMenu}
        children={
          <Search
            setSearchMenu={setSearchMenu}
            setShowSearchDrawer={setShowSearchDrawer}
          />
        }
        customStyle={"mt-6 md:-ml-8 lg:-ml-14"}
      />
    </div>
  );
}

export default Navbar;
