import React, { useState, useEffect } from "react";
import NavbarAndFooterComp from "../../../../components/NavbarAndFooterComp";
import { RoutesFetch } from "../../../../config/RoutesFetch";
import { ErrorTexts } from "../../../../constants/ErrorTexts";
import InfiniteScrollComp from "../../../../components/InfiniteScrollComp";
import RouteWaiter from "../../../../components/RouteWaiter";
import ProductList from "../../../../components/ProductList";
import InfiniteScrollCompProductList from "../../../../components/InfiniteScrollComp/ProductList";

function SavedItems() {
  const [loading, setLoading] = useState(true);
  const [wishlistData, setWishListData] = useState(null);
  const [errorText, setErrorText] = useState("");

  return (
    <NavbarAndFooterComp
      children={
        <div>
          <div>
            <InfiniteScrollComp
              url="/userSavedItem"
              responseDataName="productData"
              setLoading={setLoading}
              setErrorText={setErrorText}
              data={wishlistData}
              setData={setWishListData}
              valueOfUseEffectArray={[]}
              children={
                <RouteWaiter
                  loading={loading}
                  error={errorText}
                  data={wishlistData}
                  children={
                    <ProductList
                      data={wishlistData}
                      setData={setWishListData}
                      fromWishlist={true}
                    />
                  }
                />
              }
            />
          </div>
        </div>
      }
    />
  );
}

export default SavedItems;
