import React, { useEffect, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { Colors } from "../../../constants/Colors";
import Button from "../../../components/Button";
import TextComp from "../../../components/TextComp";
import { RoutesFetch } from "../../../config/RoutesFetch";
import { errorToastify } from "../../../components/Toastify";
import { ErrorTexts } from "../../../constants/ErrorTexts";
import RouteWaiter from "../../../components/RouteWaiter";
import CustomModal from "../../../components/Modal";
import AddressForm from "../../ProfileMenu/MyAccount/AddressBook/AddressForm";

import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { MdCheckBox } from "react-icons/md";
import InfiniteScrollComp from "../../../components/InfiniteScrollComp";

function SelectAddressModal({
  open,
  setOpen,
  setDeliveryAddress,
  deliveryAddress,
}) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [openAddNewAddressModal, setOpenAddNewAddressModal] = useState(false);

  // useEffect(() => {
  //   if (open === true) {
  //     setLoading(true);

  //     RoutesFetch({
  //       method: "get",
  //       url: "/getUserAddress",
  //     })
  //       .then((response) => {
  //         if (response.msg === "fail") {
  //           // errorToastify(ErrorTexts.sww);
  //           setErrorText(ErrorTexts.addressNotFetch);
  //         } else {
  //           setData(response.addressData);
  //         }
  //       })
  //       .catch((err) => {
  //         setErrorText(ErrorTexts.addressNotFetch);
  //         // errorToastify(ErrorTexts.sww);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // }, [open, isFocused]);

  return (
    <div className="my-24 flex items-center justify-center">
      <div className="bg-white mx-5 md:mx-0 w-full md:w-2/3 lg:w-1/2 p-2">
        <div className="flex flex-col items-end m-2 my-4 md:m-4">
          <IoCloseOutline
            color={Colors.black}
            size={40}
            className="cursor-pointer hover:p-1 duration-500"
            onClick={() => setOpen(false)}
          />
        </div>

        <div className="m-2 md:m-10">
          <div
            className="pb-2"
            onClick={() => {
              setOpenAddNewAddressModal(true);
            }}
          >
            <Button btnText={"ADD NEW ADDRESS"} />
          </div>

          <TextComp text="OR" customStyle={"text-darkGray p-2 text-sm"} />

          <TextComp
            text="SELECT ADDRESS"
            customStyle={
              "text-black font-semibold tracking-widest text-xs px-2"
            }
            onClick={() => {
              setOpen(false);
            }}
          />

          <InfiniteScrollComp
            url="/getUserAddress"
            responseDataName="addressData"
            setLoading={setLoading}
            setErrorText={setErrorText}
            data={data}
            setData={setData}
            valueOfUseEffectArray={[open, isFocused]}
            children={
              <RouteWaiter
                loading={loading}
                data={data}
                noDataChildren={
                  <div className="m-2 md:m-5">
                    <TextComp
                      text={ErrorTexts.noAddress}
                      customStyle={
                        "text-xs font-light text-darkGray tracking-wide"
                      }
                    />
                  </div>
                }
                error={errorText}
                errorChildren={
                  <div className="m-2 md:m-5">
                    <TextComp
                      text={ErrorTexts.addressNotFetch}
                      customStyle={"text-xs font-light text-red tracking-wide"}
                    />
                  </div>
                }
                children={data?.map((item, index) => (
                  <div
                    key={index}
                    className="my-5 border border-lightGray bg-lightGray shadow rounded p-2 md:p-5 tracking-wider cursor-pointer flex items-center"
                    onClick={() => {
                      setDeliveryAddress(item);
                      setOpen(false);
                    }}
                  >
                    {item._id === deliveryAddress._id ? (
                      <MdCheckBox color={Colors.black} size={24} />
                    ) : (
                      <MdCheckBoxOutlineBlank color={Colors.black} size={24} />
                    )}
                    <TextComp
                      text={`${item?.address1}, ${item?.address2}, ${item?.city}, ${item?.province}, ${item?.zip}`}
                      customStyle={"text-xs ml-2"}
                    />
                  </div>
                ))}
              />
            }
          />
        </div>
      </div>

      <CustomModal
        open={openAddNewAddressModal}
        setOpen={setOpenAddNewAddressModal}
        children={
          <AddressForm
            open={openAddNewAddressModal}
            setOpen={setOpenAddNewAddressModal}
            setIsFocused={setIsFocused}
            isFocused={isFocused}
          />
        }
      />
    </div>
  );
}

export default SelectAddressModal;
